import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Home, BarChart2, Settings, LogOut, Users, 
  FileText, History, ChevronRight,
  CookingPot, Sofa, Wallet,
  ChevronDown, Keyboard, ChevronUp, Sun, Moon,
  ChevronLeft, HandHelping, MessageSquareLock
} from "lucide-react";
import { useAuth } from '../context/AuthContext';

const Sidebar = () => {
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // Initialize theme from localStorage or system preference
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) return savedTheme === 'dark';
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(() => 
    localStorage.getItem('sidebarCollapsed') === 'true'
  );
  const [restaurantName, setRestaurantName] = useState("Restaurant App");
  const navigate = useNavigate();
  const location = useLocation();
  const { token, logout } = useAuth(); // Assuming useAuth provides a logout function

  // Update localStorage when theme changes
  useEffect(() => {
    localStorage.setItem('theme', isDarkTheme ? 'dark' : 'light');
    document.documentElement.classList.toggle('dark', isDarkTheme);
  }, [isDarkTheme]);

  // Update localStorage when sidebar collapse state changes
  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', isCollapsed.toString());
  }, [isCollapsed]);

  // Close mobile sidebar on route changes
  useEffect(() => {
    setIsMobileOpen(false);
  }, [location.pathname]);

  // Handle clicks outside sidebar with proper cleanup
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.getElementById('sidebar');
      const mobileButton = document.getElementById('mobile-menu-button');
      
      if (!sidebar || !mobileButton) return;
      
      if (isMobileOpen && 
          !sidebar.contains(event.target) && 
          !mobileButton.contains(event.target)) {
        setIsMobileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobileOpen]);

  const isBillGenerationPage = () => {
    return location.pathname.includes('/generate-bill') || 
           location.pathname.includes('/walkin-bill') || 
           location.pathname.includes('/saved-bill') ||
           location.pathname.includes('/print-kot')||
           location.pathname.includes('/print-kot2');
  };


  // Fetch restaurant name with proper error handling and loading state
  const fetchRestaurantName = async () => {
    try {
      const response = await fetch('https://www.wazwanrestaurants.com/api/settings', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setRestaurantName(data.restaurantName || "Restaurant App");
    } catch (error) {
      console.error('Error fetching restaurant name:', error);
      // Optionally show a toast/notification to user
    }
  };

  useEffect(() => {
    fetchRestaurantName();
  }, [token]); // Add token as dependency

  const sidebarItems = [
    { name: "Dashboard", icon: Home, path: "/dashboard" },
    { name: "Menu", icon: CookingPot, path: "/product" },
    { name: "Daily Expenses", icon: Wallet, path: "/expense" }, // Fixed capitalization
  ];

  const quickNavItems = [
    { name: "New Bill", icon: Keyboard, path: "/sales" },
    { name: "Dine-in Orders", icon: Users, path: "/accepted-orders" },
    { name: "KOT", icon: FileText, path: "/kot" },
    { name: "Table Activity", icon: Sofa, path: "/table-manager" },
  ];

  const reportsSubItems = [
    { name: "Expense Report", icon: Wallet, path: "/expense-report" },
    { name: "Items Report", icon: FileText, path: "/items-sales-report" }
  ];

  const quickActions = [
    { name: "Settings", icon: Settings, path: "/settings" },
  ];

  const billsHistory = [
    { name: "App Bill History", icon: History, path: "/bill-history" }
  ];

  const supportItems = [
    { name: "Support", icon: HandHelping, path: "/support?auth=true" },
    { name: "View Support", icon: MessageSquareLock, path: "/view-support" },
  ];

  const handleLogout = async () => {
    try {
      await logout(); // Use the logout function from AuthContext
      navigate("/login");
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally show error to user
    }
  };

  const getThemeColors = () => ({
    background: isDarkTheme ? "bg-slate-900" : "bg-white",
    text: isDarkTheme ? "text-slate-200" : "text-slate-800",
    border: isDarkTheme ? "border-slate-800" : "border-slate-200",
    hover: isDarkTheme ? "hover:bg-slate-800/50" : "hover:bg-slate-100",
    activeBg: isDarkTheme ? "bg-indigo-500/10" : "bg-indigo-50",
    activeText: isDarkTheme ? "text-indigo-400" : "text-indigo-600",
    headerBg: isDarkTheme ? "bg-slate-900/95" : "bg-white/95",
    menuText: isDarkTheme ? "text-slate-400" : "text-slate-600",
    scrollbarThumb: isDarkTheme ? "scrollbar-thumb-slate-600" : "scrollbar-thumb-slate-300",
    scrollbarTrack: isDarkTheme ? "scrollbar-track-slate-800/40" : "scrollbar-track-slate-100",
  });

  const colors = getThemeColors();

  const renderNavItem = ({ name, icon: Icon, path }) => {
    if (!Icon) return null;
    
    const isActive = location.pathname === path;
    
    return (
      <Link
        key={name}
        to={path}
        className={`flex items-center p-3 rounded-lg transition-all duration-200 mx-2 group ${
          isActive 
            ? `${colors.activeBg} ${colors.activeText}` 
            : `${colors.hover} ${colors.menuText} hover:${colors.text}`
        }`}
        onClick={() => setIsMobileOpen(false)}
      >
        <Icon className={`w-6 h-6 ${isActive ? colors.activeText : colors.menuText} group-hover:scale-110 transition-transform`} />
        {!isCollapsed && (
          <span className="ml-3 text-sm font-medium whitespace-nowrap">
            {name}
          </span>
        )}
      </Link>
    );
  };

  const SidebarContent = () => (
    <div className="flex flex-col h-full relative">
      {/* Collapse Toggle Button */}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={`absolute -right-4 top-6 w-8 h-8 rounded-full ${colors.background} ${colors.border} 
          shadow-lg flex items-center justify-center group hover:${colors.hover} z-50
          transition-transform duration-300 ${isCollapsed ? 'rotate-180' : ''} 
          lg:block hidden`}
        aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      >
        <ChevronLeft className={`w-5 h-5 ${colors.menuText}`} />
      </button>

      {/* Sidebar Header */}
      <div className={`sticky top-0 ${colors.headerBg} border-b ${colors.border} p-4 flex items-center justify-between z-[1]`}>
        {!isCollapsed && (
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-semibold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent truncate pr-2">
              {restaurantName}
            </h2>
          </div>
        )}
        <div className="flex items-center space-x-2 flex-shrink-0">
          <button
            onClick={() => setIsDarkTheme(!isDarkTheme)}
            className={`p-2 ${colors.hover} rounded-lg transition-colors`}
            aria-label={isDarkTheme ? "Switch to light theme" : "Switch to dark theme"}
          >
            {isDarkTheme ? (
              <Sun className="w-6 h-6 text-yellow-400" />
            ) : (
              <Moon className="w-6 h-6 text-slate-600" />
            )}
          </button>
        </div>
      </div>

      {/* Navigation Sections */}
      <nav className={`
        flex-1 overflow-y-auto
        scrollbar-thin
        ${colors.scrollbarTrack}
        ${colors.scrollbarThumb}
        hover:scrollbar-thumb-slate-400
        dark:hover:scrollbar-thumb-slate-500
        transition-colors
        scroll-smooth
        scrollbar-track-rounded-full
        scrollbar-thumb-rounded-full
        p-4
      `}>
        {/* Main Navigation */}
        <NavigationSection
          title="Main Navigation"
          items={sidebarItems}
          isCollapsed={isCollapsed}
          colors={colors}
          renderNavItem={renderNavItem}
        />
        
        {/* Quick Navigation */}
        <NavigationSection
          title="Quick Navigation"
          items={quickNavItems}
          isCollapsed={isCollapsed}
          colors={colors}
          renderNavItem={renderNavItem}
        />

        {/* Reports Section */}
        <div className="mb-6">
          {!isCollapsed && (
            <p className={`px-1 mb-2 text-sm font-semibold ${colors.menuText} uppercase tracking-wider`}>
              Reports & Analytics
            </p>
          )}
          <button
            onClick={() => setIsReportsOpen(!isReportsOpen)}
            className={`flex items-center w-full p-3 rounded-lg transition-all duration-200 mx-2 group ${
              location.pathname.startsWith("/report")
                ? `${colors.activeBg} ${colors.activeText}`
                : `${colors.hover} ${colors.menuText} hover:${colors.text}`
            }`}
            aria-expanded={isReportsOpen}
          >
            <BarChart2 className="w-6 h-6 group-hover:scale-110 transition-transform" />
            {!isCollapsed && (
              <>
                <span className="ml-3 text-sm font-medium">Reports</span>
                <span className="ml-auto">
                  {isReportsOpen ? (
                    <ChevronUp className="w-5 h-5" />
                  ) : (
                    <ChevronDown className="w-5 h-5" />
                  )}
                </span>
              </>
            )}
          </button>
          {isReportsOpen && !isCollapsed && (
            <div className="mt-1 ml-4 space-y-1">
              {reportsSubItems.map(renderNavItem)}
            </div>
          )}
        </div>

        {/* Quick Actions */}
        <NavigationSection
          title="Quick Actions"
          items={quickActions}
          isCollapsed={isCollapsed}
          colors={colors}
          renderNavItem={renderNavItem}
        />

        {/* Bills History */}
        <NavigationSection
          title="Bills History"
          items={billsHistory}
          isCollapsed={isCollapsed}
          colors={colors}
          renderNavItem={renderNavItem}
        />

        {/* Support */}
        <NavigationSection
          title="Support"
          items={supportItems}
          isCollapsed={isCollapsed}
          colors={colors}
          renderNavItem={renderNavItem}
        />
      </nav>

      {/* Logout Button */}
      <div className={`sticky bottom-0 p-4 border-t ${colors.border} ${colors.background}`}>
        <button
          onClick={handleLogout}
          className="w-full flex items-center p-3 rounded-lg transition-all duration-200
          text-red-400 hover:bg-red-500/10 hover:text-red-300 group"
          aria-label="Logout"
        >
          <LogOut className="w-6 h-6 group-hover:scale-110 transition-transform" />
          {!isCollapsed && (
            <span className="ml-3 text-sm font-medium">Logout</span>
          )}
        </button>
      </div>
    </div>
  );

  // NavigationSection component for DRY code
  const NavigationSection = ({ title, items, isCollapsed, colors, renderNavItem }) => (
    <div className="mb-6">
      {!isCollapsed && (
        <p className={`px-1 mb-2 text-sm font-semibold ${colors.menuText} uppercase tracking-wider`}>
          {title}
        </p>
      )}
      <div className="space-y-1">
        {items.map(renderNavItem)}
      </div>
    </div>
  );

  return (
    <>
      {!isBillGenerationPage() && (
        <button
          id="mobile-menu-button"
          className="lg:hidden fixed top-4 left-4 z-[60] p-3 rounded-lg bg-white dark:bg-slate-800 shadow-lg"
          onClick={() => setIsMobileOpen(!isMobileOpen)}
          aria-label={isMobileOpen ? "Close menu" : "Open menu"}
          aria-expanded={isMobileOpen}
        >
          <ChevronRight className="w-2 h-2" />
        </button>
      )}
      {/* Mobile Overlay */}
      {isMobileOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black/50 z-[55]"
          onClick={() => setIsMobileOpen(false)}
          aria-hidden="true"
        />
      )}

       {/* Sidebar Container */}
       <aside 
        id="sidebar"
        className={`fixed lg:sticky top-0 left-0 h-screen z-[70]
          ${colors.background} ${colors.text} 
          transition-all duration-300 border-r ${colors.border}
          ${isMobileOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"}
          ${isCollapsed ? "w-12" : "w-60"}
          print:hidden`}
        aria-label="Sidebar navigation"
      >
        <SidebarContent />
      </aside>
      
      {/* Content Wrapper */}
      <div 
        className={`lg:ml-0 transition-all duration-300 relative ${
          isMobileOpen ? 'ml-60' : 'ml-0'}`}
          >
            {/* Main content container */}
            <main className="min-h-screen">
              {/* Your main content goes here */}
            </main>
          </div>
        </>
      );
    };
    
    export default Sidebar;