import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem('token') || sessionStorage.getItem('token'));
  const [userEmail, setUserEmail] = useState(() => localStorage.getItem('userEmail') || sessionStorage.getItem('userEmail'));

  const login = (newToken, email) => {
    setToken(newToken);
    setUserEmail(email);
    localStorage.setItem('token', newToken); 
    sessionStorage.setItem('token', newToken); 
    sessionStorage.setItem('userEmail', email); 
  };

  const logout = () => {
    setToken(null);
    setUserEmail(null);
    localStorage.removeItem('token'); 
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userEmail'); 
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token') || sessionStorage.getItem('token');
    const storedEmail = localStorage.getItem('userEmail') || sessionStorage.getItem('userEmail');
    if (storedToken) setToken(storedToken);
    if (storedEmail) setUserEmail(storedEmail);
  }, []);

  return (
    <AuthContext.Provider value={{ token, userEmail, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
