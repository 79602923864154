import React, { useState, useEffect } from 'react';
import { PlusCircle, Edit2, Trash2, Image, X, CupSoda, CheckCircle2, Soup, Cookie, ChevronLeft, Search } from 'lucide-react';
import StockManagementDialog, { OutOfStockDialog } from './StockManagementDialog';
import { useAuth } from '../../context/AuthContext';

const ProductManager = () => {
  const { token } = useAuth();
  const [products, setProducts] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [editingProduct, setEditingProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, product: null });
  const [outOfStockDialog, setOutOfStockDialog] = useState({ isOpen: false, product: null });

  const initialFormData = {
    category: '',
    itemName: '',
    basePrice: '',
    type: '',
    unitType: '',
    variants: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [customVariants, setCustomVariants] = useState(['']);
  const [customType, setCustomType] = useState('');

  const typeOptions = [
    'Beverage', 'Starter', 'Dessert', 'Breads', 
    'Main-Course', 'Combo', 'Sweets', 'Snacks', 'Custom'
  ];

  const unitTypes = {
    Size: ['Small', 'Medium', 'Large'],
    Quantity: ['Quarter', 'Half', 'Full'],
    Weight: ['250g', '500g', '1kg'],
    Custom: ['Custom']
  };

  useEffect(() => {
    fetchProducts();
  }, [token]);

  useEffect(() => {
    filterProducts();
  }, [searchQuery, sortBy, products]);

  const filterProducts = () => {
    let filtered = [...products];
    
    if (searchQuery) {
      filtered = filtered.filter(product => 
        product.itemName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.type.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    
    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.itemName.localeCompare(b.itemName);
        case 'price':
          return a.basePrice - b.basePrice;
        case 'type':
          return a.type.localeCompare(b.type);
        default:
          return 0;
      }
    });
    
    setFilteredProducts(filtered);
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://www.wazwanrestaurants.com/api/products', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch products');
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCustomVariantChange = (index, value) => {
    const newCustomVariants = [...customVariants];
    newCustomVariants[index] = value;
    setCustomVariants(newCustomVariants);
  };

  const addCustomVariant = () => {
    setCustomVariants([...customVariants, '']);
    setFormData(prev => ({
      ...prev,
      variants: [...prev.variants, { name: '', price: '' }]
    }));
  };

  const removeCustomVariant = (index) => {
    const newCustomVariants = customVariants.filter((_, i) => i !== index);
    setCustomVariants(newCustomVariants);
    const newVariants = formData.variants.filter((_, i) => i !== index);
    setFormData(prev => ({ ...prev, variants: newVariants }));
  };

  const handlePrevStep = () => {
    setCurrentStep(prev => Math.max(1, prev - 1));
  };

  const handleNext = () => {
    // Only submit on the final step
    if (currentStep === 6) {
      handleSubmit();
    } else if (validateCurrentStep()) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const validateCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return formData.category !== '';
      case 2:
        return formData.itemName.trim() !== '';
      case 3:
        return formData.basePrice > 0;
      case 4:
        return formData.type !== '';
      case 5:
        return formData.unitType !== '';
      case 6:
        if (formData.unitType === 'Custom') {
          return formData.variants.every(v => v.name && v.price > 0);
        }
        return formData.variants.every(v => v.price > 0);
      default:
        return true;
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleVariantPriceChange = (index, price) => {
    const updatedVariants = [...formData.variants];
    updatedVariants[index].price = parseFloat(price);
    setFormData(prev => ({ ...prev, variants: updatedVariants }));
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setCurrentStep(1);
    setSelectedImage(null);
    setImagePreview('');
    setEditingProduct(null);
    setIsFormOpen(false);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    
    if (!validateCurrentStep()) {
      return;
    }
    
    const formPayload = new FormData();
    
    Object.keys(formData).forEach(key => {
      if (key === 'variants') {
        formPayload.append(key, JSON.stringify(formData[key]));
      } else {
        formPayload.append(key, formData[key]);
      }
    });
    
    if (selectedImage) {
      formPayload.append('image', selectedImage);
    }
  
    try {
      const url = editingProduct
        ? `https://www.wazwanrestaurants.com/api/products/${editingProduct._id}`
        : 'https://www.wazwanrestaurants.com/api/products';
      
      const response = await fetch(url, {
        method: editingProduct ? 'PUT' : 'POST',
        headers: {
          'Authorization': `Bearer ${token}` 
        },
        body: formPayload
      });
      
      if (!response.ok) {
        throw new Error('Failed to save product');
      }
      
      await fetchProducts();
      resetForm();
    } catch (error) {
      console.error('Error saving product:', error);
      // Remove the alert here
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        const response = await fetch(`https://www.wazwanrestaurants.com/api/products/${productId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to delete product');
        await fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const handleStockClick = (product) => {
    if (product.inStock) {
      setOutOfStockDialog({ isOpen: true, product });
    } else {
      setConfirmDialog({ 
        isOpen: true, 
        product,
        title: 'Confirm Stock Status',
        message: `Are you sure you want to mark "${product.itemName}" as in stock?`
      });
    }
  };

  const handleStockUpdate = async (productId, inStock, duration = null) => {
    try {
      const response = await fetch(`https://www.wazwanrestaurants.com/api/products/${productId}/stock`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inStock, duration }),
      });

      if (!response.ok) throw new Error('Failed to update stock status');
      await fetchProducts();
    } catch (error) {
      console.error('Error updating stock status:', error);
    }
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setFormData({
      category: product.category,
      itemName: product.itemName,
      basePrice: product.basePrice,
      type: product.type,
      unitType: product.unitType,
      variants: product.variants
    });
    
    if (product.imageUrl) {
      setImagePreview(product.imageUrl);
    }
    
    setIsFormOpen(true);
    setCurrentStep(1);
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">Select Category</h3>
            <div className="flex gap-6 justify-center">
              {['Veg', 'Non-Veg'].map((category) => (
                <button
                  key={category}
                  type="button"
                  onClick={() => {
                    setFormData(prev => ({ ...prev, category }));
                    setCurrentStep(prev => prev + 1);
                  }}
                  className={`w-40 h-40 rounded-xl border-2 transition-all duration-300 flex flex-col items-center justify-center gap-4
                    ${formData.category === category 
                      ? `border-${category === 'Veg' ? 'green' : 'red'}-500 bg-${category === 'Veg' ? 'green' : 'red'}-50` 
                      : `border-gray-300 hover:border-${category === 'Veg' ? 'green' : 'red'}-500 hover:bg-${category === 'Veg' ? 'green' : 'red'}-50`}`}
                >
                  <div className={`w-16 h-16 rounded-full bg-${category === 'Veg' ? 'green' : 'red'}-100 flex items-center justify-center`}>
                    <div className={`w-3 h-3 rounded-full bg-${category === 'Veg' ? 'green' : 'red'}-500`} />
                  </div>
                  <span className="font-medium text-lg text-gray-800">{category}</span>
                </button>
              ))}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">Item Name</h3>
            <div className="max-w-md mx-auto">
              <input
                type="text"
                value={formData.itemName}
                onChange={(e) => setFormData(prev => ({ ...prev, itemName: e.target.value }))}
                className="w-full px-4 py-3 border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-all"
                placeholder="Enter item name"
                required
              />
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">Base Price</h3>
            <div className="max-w-md mx-auto">
              <div className="relative">
                <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">₹</span>
                <input
                  type="number"
                  value={formData.basePrice}
                  onChange={(e) => setFormData(prev => ({ ...prev, basePrice: e.target.value }))}
                  className="w-full pl-8 pr-4 py-3 border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-all"
                  placeholder="Enter base price"
                  required
                />
              </div>
            </div>
          </div>
        );

        case 4:
          return (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-800 mb-6">Select Type</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 max-w-2xl mx-auto">
                {typeOptions.map(type => (
                  <button
                    key={type}
                    type="button"
                    onClick={() => {
                      if (type === 'Custom') {
                        setFormData(prev => ({ ...prev, type: '' }));
                        setCustomType('');
                      } else {
                        setFormData(prev => ({ ...prev, type }));
                        setCurrentStep(prev => prev + 1);
                      }
                    }}
                    className={`p-4 rounded-xl border-2 transition-all duration-300
                      ${(type === 'Custom' ? customType : formData.type) === type
                        ? 'border-blue-500 bg-blue-50' 
                        : 'border-gray-300 hover:border-blue-500 hover:bg-blue-50'}`}
                  >
                    <span className="font-medium text-gray-800">{type}</span>
                  </button>
                ))}
              </div>

            {formData.type === '' && (
              <div className="max-w-md mx-auto mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Enter Custom Type
                </label>
                <div className="flex gap-3">
                  <input
                    type="text"
                    value={customType}
                    onChange={(e) => setCustomType(e.target.value)}
                    className="flex-1 px-4 py-3 border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                    placeholder="Enter custom type"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      if (customType.trim()) {
                        setFormData(prev => ({ ...prev, type: customType.trim() }));
                          handleNext();
                        }
                      }}
                      disabled={!customType.trim()}
                      className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
  

          case 5:
            return (
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-800 mb-6">Select Unit Type</h3>
                <div className="grid grid-cols-2 gap-6 max-w-2xl mx-auto">
                  <button
                    type="button"
                    onClick={() => {
                      setFormData(prev => ({
                        ...prev,
                        unitType: 'Size',
                        variants: unitTypes['Size'].map(unit => ({ name: unit, price: '' }))
                      }));
                      setCurrentStep(prev => prev + 1);
                    }}
                    className="p-6 rounded-xl border-2 border-gray-300 hover:border-blue-500 hover:bg-blue-50 transition-all duration-300"
                  >
                    <div className="flex flex-col items-center gap-4">
                      <CupSoda className="w-12 h-12 text-blue-500" />
                      <div className="text-center">
                        <p className="font-medium text-lg text-gray-800">Size</p>
                        <p className="text-sm text-gray-500 mt-2">Small • Medium • Large</p>
                      </div>
                    </div>
                  </button>
          
                  <button
                    type="button"
                    onClick={() => {
                      setFormData(prev => ({
                        ...prev,
                        unitType: 'Quantity',
                        variants: unitTypes['Quantity'].map(unit => ({ name: unit, price: '' }))
                      }));
                      setCurrentStep(prev => prev + 1);
                    }}
                    className="p-6 rounded-xl border-2 border-gray-300 hover:border-blue-500 hover:bg-blue-50 transition-all duration-300"
                  >
                    <div className="flex flex-col items-center gap-4">
                      <Soup className="w-12 h-12 text-blue-500" />
                      <div className="text-center">
                        <p className="font-medium text-lg text-gray-800">Quantity</p>
                        <p className="text-sm text-gray-500 mt-2">Quarter • Half • Full</p>
                      </div>
                    </div>
                  </button>
          
                  <button
                    type="button"
                    onClick={() => {
                      setFormData(prev => ({
                        ...prev,
                        unitType: 'Weight',
                        variants: unitTypes['Weight'].map(unit => ({ name: unit, price: '' }))
                      }));
                      setCurrentStep(prev => prev + 1);
                    }}
                    className="p-6 rounded-xl border-2 border-gray-300 hover:border-blue-500 hover:bg-blue-50 transition-all duration-300"
                  >
                    <div className="flex flex-col items-center gap-4">
                      <Cookie className="w-12 h-12 text-blue-500" />
                      <div className="text-center">
                        <p className="font-medium text-lg text-gray-800">Weight</p>
                        <p className="text-sm text-gray-500 mt-2">250g • 500g • 1kg</p>
                      </div>
                    </div>
                  </button>
          
                  <button
                    type="button"
                    onClick={() => {
                      setFormData(prev => ({
                        ...prev,
                        unitType: 'Custom',
                        variants: [{ name: '', price: '' }]
                      }));
                      setCustomVariants(['']);
                      setCurrentStep(prev => prev + 1);
                    }}
                    className="p-6 rounded-xl border-2 border-gray-300 hover:border-blue-500 hover:bg-blue-50 transition-all duration-300"
                  >
                    <div className="flex flex-col items-center gap-4">
                      <PlusCircle className="w-12 h-12 text-blue-500" />
                      <div className="text-center">
                        <p className="font-medium text-lg text-gray-800">Custom</p>
                        <p className="text-sm text-gray-500 mt-2">Define your own units</p>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            );

      case 6:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">Set Variant Prices</h3>
            <div className="max-w-2xl mx-auto space-y-4">
              {formData.unitType === 'Custom' ? (
                <>
                  {customVariants.map((variant, index) => (
                    <div key={index} className="flex gap-4 items-center">
                      <input
                        type="text"
                        value={variant}
                        onChange={(e) => {
                          handleCustomVariantChange(index, e.target.value);
                          const newVariants = [...formData.variants];
                          newVariants[index] = { ...newVariants[index], name: e.target.value };
                          setFormData(prev => ({ ...prev, variants: newVariants }));
                        }}
                        className="flex-1 px-4 py-3 border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                        placeholder="Enter variant name"
                      />
                      <div className="relative w-40">
                        <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">₹</span>
                        <input
                          type="number"
                          value={formData.variants[index]?.price || ''}
                          onChange={(e) => handleVariantPriceChange(index, e.target.value)}
                          className="w-full pl-8 pr-4 py-3 border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                          placeholder="Price"
                        />
                      </div>
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => removeCustomVariant(index)}
                          className="p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                        >
                          <X className="w-5 h-5" />
                          </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addCustomVariant}
                    className="px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg flex items-center gap-2 transition-colors"
                  >
                    <PlusCircle className="w-4 h-4" />
                    Add Variant
                  </button>
                </>
              ) : (
                formData.variants.map((variant, index) => (
                  <div key={index} className="flex gap-4 items-center bg-gray-50 p-4 rounded-lg">
                    <span className="w-32 font-medium text-gray-700">{variant.name}</span>
                    <div className="relative flex-1">
                      <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">₹</span>
                      <input
                        type="number"
                        value={variant.price}
                        onChange={(e) => handleVariantPriceChange(index, e.target.value)}
                        className="w-full pl-8 pr-4 py-3 border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                        placeholder={`Price for ${variant.name}`}
                        required
                      />
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="mt-8 space-y-4">
              <h3 className="text-lg font-medium text-gray-800">Product Image (Optional)</h3>
              <div className="flex items-center gap-4">
                <label className="flex-1 cursor-pointer">
                  <div className="px-6 py-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 transition-colors">
                    <div className="flex items-center justify-center">
                      <Image className="w-6 h-6 mr-2 text-gray-400" />
                      <span className="text-gray-600">
                        {imagePreview ? 'Change Image' : 'Upload Image'}
                      </span>
                    </div>
                  </div>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    className="hidden"
                    accept="image/*"
                  />
                </label>
                {imagePreview && (
                  <div className="relative w-24 h-24">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImagePreview('');
                        setSelectedImage(null);
                      }}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1.5 hover:bg-red-600 transition-colors"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };



  return (
    <div className="min-h-screen bg-gray-50 p-4 sm:p-6">
      <div className="max-w-7xl mx-auto">
        {!isFormOpen ? (
          <div className="space-y-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <h1 className="text-2xl font-bold text-gray-800">Restaurant's Menu</h1>
              <button
                onClick={() => {
                  setEditingProduct(null);
                  setIsFormOpen(true);
                  setFormData(initialFormData);
                }}
                className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <PlusCircle className="w-5 h-5 mr-2" />
                Add New Item in Menu
              </button>
            </div>

            <div className="bg-white rounded-xl shadow-sm p-4">
              <div className="flex flex-col sm:flex-row gap-4 mb-6">
                <div className="flex-1 relative">
                  <Search className="w-5 h-5 absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search products..."
                    className="w-full pl-10 pr-4 py-2 border-2 border-gray-200 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                  />
                </div>
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="px-4 py-2 border-2 border-gray-200 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                >
                  <option value="name">Sort by Name</option>
                  <option value="price">Sort by Price</option>
                  <option value="type">Sort by Type</option>
                </select>
              </div>

              {loading ? (
                <div className="text-center py-12">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-gray-200 border-t-blue-600"></div>
                  <p className="mt-2 text-gray-600">Loading products...</p>
                </div>
              ) : filteredProducts.length === 0 ? (
                <div className="text-center py-12">
                  <p className="text-gray-600">No products found</p>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  {filteredProducts.map((product) => (
                    <div 
                      key={product._id} 
                      className={`bg-white rounded-xl border border-gray-200 overflow-hidden hover:shadow-md transition-all
                        ${!product.inStock ? 'opacity-50' : ''}`}
                    >
                      <div className="relative aspect-video">
                        {product.imageUrl ? (
                          <img
                            src={product.imageUrl}
                            alt={product.itemName}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                            <Image className="w-8 h-8 text-gray-400" />
                          </div>
                        )}
                        
                        <span className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-medium
                          ${product.category === 'Veg' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                          {product.category}
                        </span>
                      </div>

                      <div className="p-4 space-y-3">
                        <div>
                          <h3 className="font-semibold text-lg text-gray-800">{product.itemName}</h3>
                          <p className="text-gray-600 text-sm">{product.type}</p>
                        </div>

                        <div>
                          <p className="text-blue-600 font-medium mb-2">Base Price: ₹{product.basePrice}</p>
                          <div className="space-y-1">
                            <p className="text-sm font-medium text-gray-700">Variants:</p>
                            {product.variants.map((variant, idx) => (
                              <div key={idx} className="flex justify-between text-sm">
                                <span className="text-gray-600">{variant.name}</span>
                                <span className="font-medium text-gray-800">₹{variant.price}</span>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="flex justify-end gap-2 pt-2 border-t">
                          <button
                            onClick={() => handleEdit(product)}
                            className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                            title="Edit product"
                          >
                            <Edit2 className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleStockClick(product)}
                            className={`p-2 rounded-lg transition-colors ${
                              product.inStock
                                ? 'text-green-600 hover:bg-green-50 bg-green-100'
                                : 'text-gray-400 hover:bg-gray-50'
                            }`}
                            title={product.inStock ? 'Mark as Out of Stock' : 'Mark as In Stock'}
                          >
                            <CheckCircle2 className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleDelete(product._id)}
                            className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                            title="Delete product"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center justify-between mb-8">
              <h2 className="text-2xl font-bold text-gray-800">
                {editingProduct ? 'Edit Product' : 'Add New Product'}
              </h2>
              <button
                onClick={resetForm}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <div className="mb-8">
              <div className="flex items-center mb-4">
                {[1, 2, 3, 4, 5, 6].map((step) => (
                  <React.Fragment key={step}>
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center
                      ${currentStep >= step ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}>
                      {step}
                    </div>
                    {step < 6 && (
                      <div className={`flex-1 h-1 mx-2
                        ${currentStep > step ? 'bg-blue-600' : 'bg-gray-200'}`} />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              {renderFormStep()}
              
              <div className="flex justify-between mt-8 pt-4 border-t">
                {currentStep > 1 && (
                  <button
                    type="button"
                    onClick={handlePrevStep}
                    className="flex items-center px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                  >
                    <ChevronLeft className="w-4 h-4 mr-1" />
                    Back
                  </button>
                )}
                
                {currentStep < 6 ? (
                  <button
                    type="button"
                    onClick={handleNext}
                    disabled={!validateCurrentStep()}
                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-gray-300 disabled:cursor-not-allowed ml-auto"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={!validateCurrentStep()}
                    className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:bg-gray-300 disabled:cursor-not-allowed ml-auto"
                  >
                    Save Product
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
  {/* Stock Management Dialogs */}
  <StockManagementDialog
        title={confirmDialog.title}
        message={confirmDialog.message}
        isOpen={confirmDialog.isOpen}
        onClose={() => setConfirmDialog({ isOpen: false, product: null })}
        onConfirm={async () => {
          if (confirmDialog.product) {
            await handleStockUpdate(confirmDialog.product._id, true);
          }
          setConfirmDialog({ isOpen: false, product: null });
        }}
      />

      <OutOfStockDialog
        product={outOfStockDialog.product}
        isOpen={outOfStockDialog.isOpen}
        onClose={() => setOutOfStockDialog({ isOpen: false, product: null })}
        onUpdateStock={async (duration) => {
          if (outOfStockDialog.product) {
            await handleStockUpdate(outOfStockDialog.product._id, false, duration);
          }
          setOutOfStockDialog({ isOpen: false, product: null });
        }}
      />
    </div>
  );
};

export default ProductManager;