import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file

const Footer = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
   
    // Add this effect to handle navigation
    useEffect(() => {
      // Reset scroll and menu state when route changes
      setMobileMenuOpen(false);
      document.body.style.overflow = 'unset';
    }, []);
    
    useEffect(() => {
      const menuBtn = document.querySelector('.mobile-menu-btn');
      if (menuBtn) {
        if (mobileMenuOpen) {
          menuBtn.classList.add('active');
        } else {
          menuBtn.classList.remove('active');
        }
      }
    }, [mobileMenuOpen]);
    
  return (
    <div className="footer">
      <div className="footer-section">
        <div className="logo-and-contact">
          <img src="/images/Wazwan-logo.png" alt="Wazwan Logo" className="footer-logo" />
          <div className="contact-info">
            <h1>Contact Info</h1>
            <div className="line"></div>
            <ul className="contact-list">
              <li className="contact-item">
                <Link to="/login" onClick={() => setMobileMenuOpen(false)} className="text-white hover:text-gray-300 text-lg font-light">
                  Product
                </Link>
              </li>
              
              <li className="contact-item">
                <svg
                  aria-hidden="true"
                  className="svg-icons"
                  viewBox="0 0 384 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                </svg>
                Chandigarh, India <br />
                Mohali, India
              </li>

              <li className="contact-item">
                <svg
                  aria-hidden="true"
                  className="svg-icons"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                </svg>
                +91 884-7026594 <br />
                +91 73888 48178
              </li>
          
              <li className="contact-item">
                <svg
                  aria-hidden="true"
                  className="svg-icons"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path>
                </svg>
                contact.wazwan@gmail.com
              </li>
            </ul>
          </div>
        </div>
        <div className="social-media">
          <h1>Follow Us</h1>
          <div className="line"></div>
          <span>Connect with us on social media.</span>
          <div className="social-icons">
            <a href="https://www.facebook.com/people/Wazwan-Legacy/61556216672676/?mibextid=LQQJ4d&rdid=Hol2WGch2Qzv04WD&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F2wZwvR22bwzE7a1M%2F%3Fmibextid%3DLQQJ4d" target="_blank" rel="noopener noreferrer">
              <svg width="24" height="24" fill="#333" viewBox="0 0 24 24">
                <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.326v21.348C0 23.403.597 24 1.326 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.794.143v3.24h-1.918c-1.504 0-1.796.715-1.796 1.764v2.311h3.588l-.467 3.622h-3.121V24h6.116c.729 0 1.326-.597 1.326-1.326V1.326C24 .597 23.403 0 22.675 0z" />
              </svg>
            </a>
            <a href="https://www.instagram.com/wazwan_legacy/" target="_blank" rel="noopener noreferrer">
              <svg width="24" height="24" fill="#333" viewBox="0 0 24 24">
                <path d="M12 2.162c3.204 0 3.584.012 4.849.07 1.366.062 2.633.354 3.608 1.33.974.974 1.267 2.24 1.33 3.608.058 1.265.069 1.645.069 4.848s-.012 3.584-.07 4.849c-.062 1.366-.354 2.633-1.33 3.608-.974.974-2.24 1.267-3.608 1.33-1.265.058-1.645.069-4.849.069s-3.584-.012-4.849-.07c-1.366-.062-2.633-.354-3.608-1.33C.553 18.182.26 16.826.198 15.46.14 14.195.13 13.015.13 12c0-3.204.012-3.584.07-4.849.062-1.366.354-2.633 1.33-3.608C2.667 2.342 3.84 2.126 5.117 2.07 6.395 2.012 6.804 2 12 2zm0 3.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zm0 10.164a3.994 3.994 0 1 1 0-7.988 3.994 3.994 0 0 1 0 7.988zm6.406-11.845a1.44 1.44 0 1 0 0-2.882 1.44 1.44 0 0 0 0 2.882z" />
              </svg>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <svg width="24" height="24" fill="#333" viewBox="0 0 24 24">
                <path d="M23.954 4.569c-.885.39-1.83.654-2.825.775a4.958 4.958 0 0 0 2.163-2.724 9.865 9.865 0 0 1-3.127 1.184 4.936 4.936 0 0 0-8.401 4.49A14.01 14.01 0 0 1 1.671 3.149 4.933 4.933 0 0 0 3.192 9.72a4.936 4.936 0 0 1-2.23-.616c-.054 2.282 1.582 4.415 3.946 4.89a4.935 4.935 0 0 1-2.224.085c.627 1.956 2.444 3.379 4.6 3.419a9.874 9.874 0 0 1-6.102 2.104c-.394 0-.779-.023-1.158-.067a13.933 13.933 0 0 0 7.548 2.209c9.057 0 14.01-7.502 14.01-14.01 0-.213-.005-.426-.014-.637a9.936 9.936 0 0 0 2.455-2.549z" />
              </svg>
            </a>
            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
              <svg width="24" height="24" fill="#333" viewBox="0 0 24 24">
                <path d="M17.472 14.382c-.297-.148-1.76-.867-2.033-.964-.273-.099-.472-.148-.672.148-.198.297-.768.964-.94 1.163-.173.198-.347.223-.644.074-.297-.148-1.255-.462-2.39-1.474-.883-.79-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.132.297-.347.446-.52.149-.173.198-.297.298-.495.099-.198.05-.372-.025-.521-.074-.148-.672-1.614-.923-2.22-.242-.581-.487-.502-.672-.512l-.573-.012c-.198 0-.52.074-.792.372-.273.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.876 1.213 3.074.149.198 2.098 3.202 5.083 4.487.711.307 1.264.491 1.696.629.713.226 1.362.194 1.875.118.572-.085 1.76-.719 2.006-1.413.247-.694.247-1.29.173-1.413-.074-.123-.273-.198-.572-.347zM11.997 2c5.514 0 9.997 4.483 9.997 9.997a9.948 9.948 0 0 1-1.608 5.402l1.127 4.101-4.202-1.104A9.942 9.942 0 0 1 12 22c-5.514 0-9.997-4.483-9.997-9.997S6.483 2 11.997 2z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;