import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Printer, ArrowLeft } from 'lucide-react';

const PrintKOT3 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { product, tableInfo, items, fromHoldBill } = location.state || {};
  const [currentTime] = useState(new Date());

  const handlePrint = () => {
    window.print();
  };

  const handleGoBack = () => {
    navigate('/sales');
  };

  if (!product && (!tableInfo || !items || items.length === 0)) {
    return (
      <div className="w-full min-h-screen flex flex-col justify-center items-center p-4">
        <div className="text-center">
          <p className="text-xl font-semibold text-gray-600 mb-4">No KOT information available</p>
          <button 
            onClick={handleGoBack} 
            className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            <ArrowLeft className="mr-2" size={18} />
            Return to Sales
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full p-4">
      {/* Navigation and Print Button */}
      <div className="print:hidden flex justify-between items-center mb-4">
        <button 
          onClick={handleGoBack} 
          className="flex items-center px-4 py-2 bg-yellow-300 text-black rounded"
        >
          <ArrowLeft className="mr-2" size={18} />
          Back
        </button>
        <button 
          onClick={handlePrint} 
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded"
        >
          <Printer className="mr-2" size={18} />
          Print KOT
        </button>
      </div>

      {/* KOT Content */}
      <div className="w-64 mx-auto text-sm">
        <div className="text-center mb-4">
          <div className="text-xl font-bold mb-2">*** KOT ***</div>
          <div className="text-base mb-1">Date: {currentTime.toLocaleDateString()}</div>
          <div className="text-base mb-1">Time: {currentTime.toLocaleTimeString()}</div>
          {tableInfo && <div className="text-base mb-1">Table: {tableInfo.name}</div>}
        </div>

        <div className="border-t-2 border-b-2 border-gray-300 my-3"></div>

        {/* Product or Items Details */}
        <div className="mb-4">
          {product ? (
            <div className="flex text-base justify-between mb-2">
              <div className="w-32 truncate">{product.itemName}</div>
            </div>
          ) : (
            items && items.map((item, index) => (
              <div key={index} className="flex text-base justify-between mb-2">
                <div className="w-32 truncate">{item.itemName}</div>
                <div>x{item.quantity}</div>
              </div>
            ))
          )}
        </div>

        <div className="border-t-2 border-gray-300 my-3"></div>

        <div className="text-center mt-4 text-base">
          <p>*** End of KOT ***</p>
        </div>
      </div>

      <style>
        {`
          @media print {
            @page {
              size: 80mm auto;
              margin: 0;
            }
            body {
              width: 80mm;
              padding: 5mm;
            }
            .print\\:hidden {
              display: none !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PrintKOT3;