import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Printer } from 'lucide-react';

const PrintKOT = () => {
  const location = useLocation();
  const { tableInfo, items } = location.state || {};
  const [currentTime] = useState(new Date());

  if (!tableInfo || !items) {
    return <div className="text-base text-center p-4">No KOT information found</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="w-full">
      {/* Print Button */}
      <div className="print:hidden mb-4">
        <button
          onClick={handlePrint}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded text-base"
        >
          <Printer className="mr-2" size={18} />
          Print KOT
        </button>
      </div>

      {/* KOT Content */}
      <div className="w-64 mx-auto text-sm">
        <div className="text-center mb-4">
          <div className="text-xl font-bold mb-2">*** KOT ***</div>
          <div className="text-base mb-1">Table: {tableInfo.name}</div>
          <div className="text-base mb-1">Date: {currentTime.toLocaleDateString()}</div>
          <div className="text-base mb-1">Time: {currentTime.toLocaleTimeString()}</div>
        </div>

        <div className="border-t-2 border-b-2 border-gray-300 my-3"></div>

        {/* Items */}
        <div className="mb-4">
          {items.map((item, index) => (
            <div key={index} className="grid grid-cols-[auto,1fr] gap-2 text-base mb-2">
              <div className="text-center w-8">{item.quantity}</div>
              <div className="whitespace-normal break-words overflow-wrap-anywhere min-w-0">
                {item.itemName}
              </div>
            </div>
          ))}
        </div>

        <div className="border-t-2 border-gray-300 my-3"></div>

        <div className="text-center mt-4 text-base">
          <p>*** End of KOT ***</p>
        </div>
      </div>

      <style>
        {`
          @media print {
            @page {
              size: 80mm auto;
              margin: 0;
            }
            body {
              width: 80mm;
              padding: 5mm;
            }
            .print\\:hidden {
              display: none !important;
            }
          }
          .overflow-wrap-anywhere {
            overflow-wrap: anywhere;
            word-break: break-word;
          }
        `}
      </style>
    </div>
  );
};

export default PrintKOT;
