import React, { useState } from 'react';
import { X, CheckCircle2 } from 'lucide-react';

// Stock Management Dialog Component
const StockManagementDialog = ({ title, message, isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full shadow-xl">
        <div className="p-4 border-b">
          <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
          <p className="text-gray-600 mt-1">{message}</p>
        </div>
        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

// Out of Stock Dialog Component
export const OutOfStockDialog = ({ product, isOpen, onClose, onUpdateStock }) => {
  const [selectedDuration, setSelectedDuration] = useState('2hour');

  const durations = [
    { value: '2hour', label: '2 Hours' },
    { value: '6hour', label: '6 Hours' },
    { value: '1day', label: '1 Day' },
    { value: '1week', label: '1 Week' },
    { value: 'indefinite', label: 'Until manually enabled' },
  ];

  const handleConfirm = async () => {
    await onUpdateStock(selectedDuration);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full shadow-xl">
        <div className="p-4 border-b flex justify-between items-center">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">Out of Stock Duration</h2>
            <p className="text-gray-600 mt-1">
              How long should "{product?.itemName}" be marked as out of stock?
            </p>
          </div>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        <div className="p-4">
          <div className="grid gap-3">
            {durations.map((duration) => (
              <button
                key={duration.value}
                onClick={() => setSelectedDuration(duration.value)}
                className={`flex items-center justify-between p-3 rounded-lg border-2 transition-all
                  ${selectedDuration === duration.value 
                    ? 'border-blue-500 bg-blue-50' 
                    : 'border-gray-200 hover:border-blue-500'}`}
              >
                <span className="font-medium text-gray-800">{duration.label}</span>
                {selectedDuration === duration.value && (
                  <CheckCircle2 className="w-5 h-5 text-blue-500" />
                )}
              </button>
            ))}
          </div>
        </div>

        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default StockManagementDialog;