import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { 
  Building2, 
  PenSquare, 
  Printer, 
  Download,
  Clock,
  Percent
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Dashboard = () => {
  const { token, userEmail } = useAuth();
  const navigate = useNavigate();
  const [todayBills, setTodayBills] = useState([]);
  const [settings, setSettings] = useState(null);
  const [editingBill, setEditingBill] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRemoveItemConfirm, setShowRemoveItemConfirm] = useState(null);
  const [analytics, setAnalytics] = useState({
    totalRevenue: 0,
    totalOrders: 0,
    averageOrderValue: 0,
    topSellingItems: [],
    totalDiscount: 0
  });

  useEffect(() => {
    fetchData();
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://www.wazwanrestaurants.com/api/products', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.get('https://www.wazwanrestaurants.com/api/settings', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        fetchBills(),
        fetchSettings(),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBills = async () => {
    try {
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setHours(endDate.getHours() - 24);

      const startISO = startDate.toISOString();
      const endISO = endDate.toISOString();

      const [savedOrdersRes, walkinOrdersRes] = await Promise.all([
        axios.get(`https://www.wazwanrestaurants.com/api/saved-orders`, {
          params: {
            start: startISO,
            end: endISO
          },
          headers: { 'Authorization': `Bearer ${token}` }
        }),
        axios.get(`https://www.wazwanrestaurants.com/api/walkin-orders`, {
          params: {
            start: startISO,
            end: endISO
          },
          headers: { 'Authorization': `Bearer ${token}` }
        })
      ]);

      const processOrder = (order, type) => {
        const orderDate = new Date(order.createdAt || order.savedAt);
        if (orderDate >= startDate && orderDate <= endDate) {
          const subtotal = order.items.reduce((sum, item) => 
            sum + (item.quantity * item.price), 0);
          const cgst = subtotal * 0.025;
          const sgst = subtotal * 0.025;
          return {
            ...order,
            type,
            subtotal: subtotal || 0,
            cgst: cgst || 0,
            sgst: sgst || 0,
            totalAmount: subtotal + cgst + sgst || 0
          };
        }
        return null;
      };

      const savedOrders = savedOrdersRes.data
        .map(order => processOrder(order, 'Dine-In'))
        .filter(Boolean);
      const walkinOrders = walkinOrdersRes.data
        .map(order => processOrder(order, 'Walk-In'))
        .filter(Boolean);

      const allOrders = [...savedOrders, ...walkinOrders]
        .sort((a, b) => new Date(b.createdAt || b.savedAt) - new Date(a.createdAt || a.savedAt));

      setTodayBills(allOrders);
      calculateAnalytics(allOrders);
    } catch (error) {
      console.error('Error fetching bills:', error);
    }
  };

  const calculateAnalytics = (bills) => {
    const revenue = bills.reduce((sum, bill) => sum + bill.totalAmount, 0);
    const totalDiscount = bills.reduce((sum, bill) => sum + (bill.discountAmount || 0), 0);
    const avgOrder = bills.length > 0 ? revenue / bills.length : 0;
    
    const itemCounts = {};
    bills.forEach(bill => {
      bill.items.forEach(item => {
        const itemName = item.name || item.itemName;
        itemCounts[itemName] = (itemCounts[itemName] || 0) + item.quantity;
      });
    });

    const topItems = Object.entries(itemCounts)
      .sort(([,a], [,b]) => b - a)
      .slice(0, 5)
      .map(([name, count]) => ({ name, count }));

    setAnalytics({
      totalRevenue: revenue,
      totalOrders: bills.length,
      averageOrderValue: avgOrder,
      topSellingItems: topItems,
      totalDiscount: totalDiscount
    });
  };

  const handleEditBill = async (bill) => {
    try {
      const endpoint = bill.type === 'Dine-In'
        ? `https://www.wazwanrestaurants.com/api/saved-orders/${bill._id}`
        : `https://www.wazwanrestaurants.com/api/walkin-orders/${bill._id}`;

      const formattedItems = editingBill.items.map(item => ({
        quantity: item.quantity,
        price: item.price,
        totalPrice: item.quantity * item.price,
        name: item.itemName || item.name,
        itemName: item.itemName || item.name,
        _id: item._id
      }));

      const subtotal = formattedItems.reduce((sum, item) => sum + item.totalPrice, 0);
      const discountAmount = editingBill.discountType === 'percentage' 
        ? (subtotal * (editingBill.discountValue / 100))
        : (editingBill.discountValue || 0);
      
      const discountedSubtotal = subtotal - discountAmount;
      const cgst = discountedSubtotal * 0.025;
      const sgst = discountedSubtotal * 0.025;
      const totalAmount = discountedSubtotal + cgst + sgst;

      const updatedBill = {
        ...editingBill,
        items: formattedItems,
        subtotal,
        subTotal: subtotal,
        discountType: editingBill.discountType || 'amount',
        discountValue: editingBill.discountValue || 0,
        discountAmount,
        cgst,
        sgst,
        totalAmount,
        paymentMode: editingBill.paymentMode || 'Cash',
        isEdited: true,
        lastEditedAt: new Date().toISOString()
      };

      const response = await axios.put(endpoint, updatedBill, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.status === 200) {
        setEditingBill(null);
        await fetchBills();
      }
    } catch (error) {
      console.error('Error updating bill:', error);
    }
  };

  const handleAddItem = () => {
    if (products.length > 0) {
      const product = products[0];
      const defaultVariant = product.variants && product.variants.length > 0 ? product.variants[0] : null;
      const newItem = {
        name: product.itemName,
        itemName: product.itemName,
        quantity: 1,
        price: defaultVariant ? defaultVariant.price : product.basePrice,
        variantName: defaultVariant ? defaultVariant.name : null,
        totalPrice: defaultVariant ? defaultVariant.price : product.basePrice,
        _id: product._id,
        isNew: true,
        variants: product.variants || []
      };
      
      setEditingBill({
        ...editingBill,
        items: [...editingBill.items, newItem]
      });
    }
  };  

  const handleItemChange = (index, productId) => {
    const product = products.find(p => p._id === productId);
    if (product) {
      const defaultVariant = product.variants && product.variants.length > 0 ? product.variants[0] : null;
      const quantity = editingBill.items[index].quantity || 1;
      const newItems = [...editingBill.items];
      newItems[index] = {
        ...newItems[index],
        itemName: product.itemName,
        name: product.itemName,
        price: defaultVariant ? defaultVariant.price : product.basePrice,
        variantName: defaultVariant ? defaultVariant.name : null,
        totalPrice: (defaultVariant ? defaultVariant.price : product.basePrice) * quantity,
        _id: product._id,
        isNew: true,
        variants: product.variants || []
      };
      setEditingBill({
        ...editingBill,
        items: newItems
      });
    }
  };

  const handleVariantChange = (index, variantName) => {
    const item = editingBill.items[index];
    const variant = item.variants.find(v => v.name === variantName);
    if (variant) {
      const newItems = [...editingBill.items];
      newItems[index] = {
        ...item,
        price: variant.price,
        variantName: variant.name,
        totalPrice: variant.price * item.quantity
      };
      setEditingBill({
        ...editingBill,
        items: newItems
      });
    }
  };

  const handleQuantityChange = (index, quantity) => {
    const newItems = [...editingBill.items];
    const parsedQuantity = parseInt(quantity) || 0;
    newItems[index] = {
      ...newItems[index],
      quantity: parsedQuantity,
      totalPrice: newItems[index].price * parsedQuantity
    };
    setEditingBill({
      ...editingBill,
      items: newItems
    });
  };

  const handleRemoveItem = (index) => {
    if (showRemoveItemConfirm === index) {
      const newItems = editingBill.items.filter((_, idx) => idx !== index);
      setEditingBill({
        ...editingBill,
        items: newItems
      });
      setShowRemoveItemConfirm(null);
    } else {
      setShowRemoveItemConfirm(index);
    }
  };

  const handlePrint = (bill) => {
    navigate(`/generate-bill-3/${bill._id}`);
  };

  const handleExport = () => {
    const csv = convertToCSV(todayBills);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `bills-export-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const convertToCSV = (bills) => {
    const headers = ['Order ID', 'Type', 'Table', 'Time', 'Edited', 'Subtotal', 'GST', 'Total'];
    const rows = bills.map(bill => [
      bill.orderId,
      bill.type,
      bill.selectedTable || 'N/A',
      formatTime(bill.createdAt || bill.savedAt),
      bill.isEdited ? 'Yes' : 'No',
      bill.subtotal,
      bill.cgst + bill.sgst,
      bill.totalAmount
    ]);
    return [headers, ...rows].map(row => row.join(',')).join('\n');
  };

  const formatCurrency = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '₹0.00' : `₹${number.toFixed(2)}`;
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const isEditable = (bill) => {
    const billDate = new Date(bill.createdAt || bill.savedAt);
    const now = new Date();
    const diffInMinutes = (now - billDate) / (1000 * 60);
    return diffInMinutes <= 20;
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="bg-white border-b">
        <div className="max-w-[1920px] mx-auto p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              {settings?.logoUrl ? (
                <img 
                  src={settings.logoUrl} 
                  alt="Restaurant Logo" 
                  className="w-16 h-16 object-contain rounded-lg"
                />
              ) : (
                <Building2 className="w-16 h-16 text-gray-400" />
              )}
              <div>
                <h1 className="text-3xl font-bold text-gray-800">
                  {settings?.restaurantName || 'Restaurant Dashboard'}
                </h1>
                <p className="text-xl text-gray-600">{settings?.businessEmail || 'email'}</p>
                <p className="text-lg text-gray-600">{settings?.location || 'Location'}</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={handleExport}
                className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
              >
                <Download className="w-5 h-5" />
                Export
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Analytics Cards - Updated to include discount */}
      <div className="max-w-[1920px] mx-auto p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-6">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 text-sm mb-1">Total Revenue</h3>
            <p className="text-2xl font-bold">{formatCurrency(analytics.totalRevenue)}</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 text-sm mb-1">Total Orders</h3>
            <p className="text-2xl font-bold">{analytics.totalOrders}</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 text-sm mb-1">Average Order Value</h3>
            <p className="text-2xl font-bold">{formatCurrency(analytics.averageOrderValue)}</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 text-sm mb-1">Total Discounts</h3>
            <p className="text-2xl font-bold">{formatCurrency(analytics.totalDiscount)}</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-gray-500 text-sm mb-1">Top Selling Item</h3>
            <p className="text-2xl font-bold">
              {analytics.topSellingItems[0]?.name || 'N/A'}
            </p>
          </div>
        </div>

        {/* Bills Table - Updated to show discount */}
        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-6 border-b">
            <h2 className="text-xl font-semibold">Recent Orders (Last 24 Hours)</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="p-4 text-left text-gray-600 font-medium border-b">Type</th>
                  <th className="p-4 text-left text-gray-600 font-medium border-b">Bill ID</th>
                  <th className="p-4 text-left text-gray-600 font-medium border-b">Table</th>
                  <th className="p-4 text-left text-gray-600 font-medium border-b">Time</th>
                  <th className="p-4 text-left text-gray-600 font-medium border-b">Items</th>
                  <th className="p-4 text-right text-gray-600 font-medium border-b">Subtotal</th>
                  <th className="p-4 text-right text-gray-600 font-medium border-b">Discount</th>
                  <th className="p-4 text-right text-gray-600 font-medium border-b">GST</th>
                  <th className="p-4 text-right text-gray-600 font-medium border-b">Total</th>
                  <th className="p-4 text-center text-gray-600 font-medium border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {todayBills.map((bill) => (
                  <tr key={bill._id} className="hover:bg-gray-50 transition-colors">
                    <td className="p-4 border-b">
                      <div className="flex flex-col gap-1">
                        <span className={`px-2 py-1 rounded-full text-sm ${
                          bill.type === 'Dine-In' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                        }`}>
                          {bill.type}
                        </span>
                        {bill.isEdited && (
                          <span className="flex items-center gap-1 text-xs text-gray-500">
                            <Clock className="w-3 h-3" />
                            Edited {new Date(bill.lastEditedAt).toLocaleString()}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="p-4 border-b">{bill.orderId}</td>
                    <td className="p-4 border-b">{bill.selectedTable || 'N/A'}</td>
                    <td className="p-4 border-b">{formatTime(bill.createdAt || bill.savedAt)}</td>
                    <td className="p-4 border-b">
                      <div className="max-h-24 overflow-y-auto">
                        {bill.items.map((item, idx) => (
                          <div key={idx} className="text-sm text-gray-600">
                            {item.quantity}x {item.itemName || item.name} - {formatCurrency(item.price)}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="p-4 border-b text-right">{formatCurrency(bill.subtotal)}</td>
                    <td className="p-4 border-b text-right">{formatCurrency(bill.discountAmount || 0)}</td>
                    <td className="p-4 border-b text-right">{formatCurrency(bill.cgst + bill.sgst)}</td>
                    <td className="p-4 border-b text-right font-medium">
                      {formatCurrency(bill.totalAmount)}
                    </td>
                    <td className="p-4 border-b">
                      <div className="flex justify-center gap-2">
                        {isEditable(bill) && (
                          <button
                            onClick={() => setEditingBill(bill)}
                            className="p-2 text-blue-600 hover:text-blue-800 transition-colors"
                            title="Edit Bill"
                          >
                            <PenSquare className="w-5 h-5" />
                          </button>
                        )}
                        <button
                          onClick={() => handlePrint(bill)}
                          className="p-2 text-gray-600 hover:text-gray-800 transition-colors"
                          title="Print Bill"
                        >
                          <Printer className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {todayBills.length === 0 && (
                  <tr>
                    <td colSpan="9" className="p-8 text-center text-gray-500">
                      No bills found in the last 24 hours
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Edit Bill Modal - Updated with discount section */}
      {editingBill && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold">Edit Bill #{editingBill.orderId}</h2>
              <button
                onClick={() => setEditingBill(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Table Number
                </label>
                <input
                  type="text"
                  value={editingBill.selectedTable || ''}
                  onChange={(e) => setEditingBill({
                    ...editingBill,
                    selectedTable: e.target.value
                  })}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Items
                </label>
                <div className="space-y-2">
                  {editingBill.items.map((item, index) => (
                    <div key={index} className="flex items-center gap-4">
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                        className="w-20 p-2 border rounded-lg"
                        min="1"
                      />
                      {item.isNew ? (
                        <div className="flex-1 flex gap-2">
                          <select
                            value={item._id || ''}
                            onChange={(e) => handleItemChange(index, e.target.value)}
                            className="flex-1 p-2 border rounded-lg"
                          >
                            <option value="">Select Product</option>
                            {products.map(product => (
                              <option key={product._id} value={product._id}>
                                {product.itemName}
                              </option>
                            ))}
                          </select>
                          {item.variants && item.variants.length > 0 && (
                            <select
                              value={item.variantName || ''}
                              onChange={(e) => handleVariantChange(index, e.target.value)}
                              className="w-1/3 p-2 border rounded-lg"
                            >
                              {item.variants.map(variant => (
                                <option key={variant.name} value={variant.name}>
                                  {variant.name} - {formatCurrency(variant.price)}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      ) : (
                        <div className="flex-1 flex gap-2">
                          <div className="flex-1 p-2 border rounded-lg bg-gray-50">
                            {item.itemName || item.name} 
                            {item.variantName && ` - ${item.variantName}`}
                          </div>
                          <div className="w-1/3 p-2 border rounded-lg bg-gray-50">
                            {formatCurrency(item.price)}
                          </div>
                        </div>
                      )}
                      <div className="w-32 p-2 border rounded-lg bg-gray-50 text-right">
                        {formatCurrency(item.totalPrice)}
                      </div>
                      <div className="relative">
                        <button
                          onClick={() => handleRemoveItem(index)}
                          className="p-2 text-red-600 hover:text-red-800 transition-colors"
                        >
                          ×
                        </button>
                        {showRemoveItemConfirm === index && (
                          <div className="absolute right-0 top-full mt-2 bg-white border rounded-lg shadow-lg p-2 z-10">
                            <p className="text-sm mb-2">Remove this item?</p>
                            <div className="flex gap-2">
                              <button
                                onClick={() => handleRemoveItem(index)}
                                className="px-3 py-1 bg-red-600 text-white text-sm rounded hover:bg-red-700"
                              >
                                Yes
                              </button>
                              <button
                                onClick={() => setShowRemoveItemConfirm(null)}
                                className="px-3 py-1 bg-gray-200 text-gray-800 text-sm rounded hover:bg-gray-300"
                              >
                                No
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={handleAddItem}
                  className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  + Add Item
                </button>
              </div>

              <div className="border-t pt-4 mt-6">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Discount Type
                  </label>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value="amount"
                        checked={editingBill.discountType === 'amount'}
                        onChange={(e) => setEditingBill({
                          ...editingBill,
                          discountType: e.target.value,
                          discountValue: 0
                        })}
                        className="mr-2"
                      />
                      Fixed Amount
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value="percentage"
                        checked={editingBill.discountType === 'percentage'}
                        onChange={(e) => setEditingBill({
                          ...editingBill,
                          discountType: e.target.value,
                          discountValue: 0
                        })}
                        className="mr-2"
                      />
                      Percentage
                    </label>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Discount Value
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      type="number"
                      value={editingBill.discountValue || 0}
                      onChange={(e) => setEditingBill({
                        ...editingBill,
                        discountValue: parseFloat(e.target.value) || 0
                      })}
                      min="0"
                      max={editingBill.discountType === 'percentage' ? 100 : undefined}
                      step="0.01"
                      className="w-32 p-2 border rounded-lg"
                    />
                    <span className="text-gray-600">
                      {editingBill.discountType === 'percentage' ? '%' : '₹'}
                    </span>
                  </div>
                </div>

                {/* Updated totals section with discount calculation */}
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Subtotal:</span>
                    <span>{formatCurrency(editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0))}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Discount:</span>
                    <span className="text-red-600">
                      -{formatCurrency(
                        editingBill.discountType === 'percentage'
                          ? (editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0) * (editingBill.discountValue / 100))
                          : editingBill.discountValue || 0
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">CGST (2.5%):</span>
                    <span>{formatCurrency(
                      (editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0) -
                      (editingBill.discountType === 'percentage'
                        ? (editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0) * (editingBill.discountValue / 100))
                        : editingBill.discountValue || 0)) * 0.025
                    )}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">SGST (2.5%):</span>
                    <span>{formatCurrency(
                      (editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0) -
                      (editingBill.discountType === 'percentage'
                        ? (editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0) * (editingBill.discountValue / 100))
                        : editingBill.discountValue || 0)) * 0.025
                    )}</span>
                  </div>
                  <div className="flex justify-between font-medium text-lg border-t pt-2 mt-2">
                    <span>Total:</span>
                    <span>{formatCurrency(
                      (editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0) -
                      (editingBill.discountType === 'percentage'
                        ? (editingBill.items.reduce((sum, item) => sum + (item.totalPrice || 0), 0) * (editingBill.discountValue / 100))
                        : editingBill.discountValue || 0)) * 1.05
                    )}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Payment Mode
              </label>
              <select
                value={editingBill.paymentMode || 'Cash'}
                onChange={(e) => setEditingBill({
                  ...editingBill,
                  paymentMode: e.target.value
                })}
                className="w-full p-2 border rounded-lg"
              >
                <option value="Cash">Cash</option>
                <option value="UPI">UPI</option>
                <option value="Card">Card</option>
                <option value="Other"></option>
              </select>
            </div>

            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setEditingBill(null)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => handleEditBill(editingBill)}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-2 text-gray-600">Loading...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;