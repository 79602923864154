import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { api } from "../api/api";
import '../styles/UnifiedAuth.css';
import Toast from './Toast';

const AccountDelete = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleRequestOTP = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    
    try {
      const response = await api("/auth/request-delete-otp", "POST", { 
        email, 
        password 
      });
      
      setToastMessage("Verification code sent to your email");
      setToastType("success");
      setShowToast(true);
      setShowOtpInput(true);
    } catch (err) {
      setError(err.message || "Failed to send verification code. Please check your credentials.");
      setToastMessage(err.message || "Failed to send verification code");
      setToastType("error");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    
    try {
      const response = await api("/auth/delete-account", "POST", { 
        email, 
        password,
        otp 
      });
      
      setToastMessage("Account successfully deleted");
      setToastType("success");
      setShowToast(true);

      logout();
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      setError(err.message || "Failed to delete account. Please check your verification code.");
      setToastMessage(err.message || "Failed to delete account");
      setToastType("error");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="account-delete-container" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #f5f7fa 0%, #e8eaec 100%)',
      padding: '20px'
    }}>
      {showToast && (
        <Toast
          message={toastMessage}
          type={toastType}
          onClose={() => setShowToast(false)}
        />
      )}

      <div style={{
        width: '100%',
        maxWidth: '450px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '30px',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div style={{
          height: '8px',
          backgroundColor: '#D32F2F',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0
        }}></div>
        
        <h2 style={{
          fontSize: '26px',
          fontWeight: 'bold',
          marginBottom: '25px',
          color: '#D32F2F',
          textAlign: 'center'
        }}>
          Account Deletion
        </h2>

        <div style={{
          backgroundColor: "#FFF8E1",
          border: "1px solid #FFE082",
          borderRadius: "6px",
          padding: "15px",
          marginBottom: "25px",
          color: "#E65100",
          fontSize: '14px'
        }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              <line x1="12" y1="9" x2="12" y2="13"></line>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
            <strong style={{ marginLeft: '10px', fontSize: '16px' }}>Critical Warning</strong>
          </div>
          <p>This action <strong>permanently deletes</strong> your account and all associated data:</p>
          <ul style={{ marginLeft: '20px', marginTop: '8px' }}>
            <li>Restaurant profile and settings</li>
          </ul>
          <p style={{ marginTop: '8px' }}><strong>This action cannot be undone.</strong></p>
        </div>

        {error && (
          <div style={{
            backgroundColor: "#FFEBEE",
            border: "1px solid #FFCDD2",
            borderRadius: "4px",
            padding: "12px",
            marginBottom: "20px",
            color: "#B71C1C"
          }}>
            {error}
          </div>
        )}

        {!showOtpInput ? (
          <form onSubmit={handleRequestOTP}>
            <div style={{ marginBottom: '20px' }}>
              <label style={{
                display: 'block',
                marginBottom: '8px',
                fontWeight: '500',
                color: '#333'
              }}>Account Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email address"
                style={{
                  width: '100%',
                  padding: '12px 15px',
                  borderRadius: '4px',
                  border: '1px solid #ddd',
                  fontSize: '14px',
                  transition: 'border-color 0.3s',
                  outline: 'none'
                }}
              />
            </div>

            <div style={{ marginBottom: '25px' }}>
              <label style={{
                display: 'block',
                marginBottom: '8px',
                fontWeight: '500',
                color: '#333'
              }}>Account Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your password"
                style={{
                width: '100%',
                padding: '12px 15px',
                borderRadius: '4px',
                border: '1px solid #ddd',
                fontSize: '14px',
                transition: 'border-color 0.3s',
                outline: 'none'
                }}
            />
            </div>

            <button
              type="submit"
              disabled={loading}
              style={{
                width: '100%',
                padding: '14px',
                backgroundColor: "#D32F2F",
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                fontSize: '16px',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {loading ? (
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <svg style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '10px',
                    animation: 'spin 1s linear infinite'
                  }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Verifying Identity...
                </span>
              ) : (
                "Send Verification Code"
              )}
            </button>
          </form>
        ) : (
          <form onSubmit={handleDeleteAccount}>
            <div style={{ 
              padding: '15px', 
              backgroundColor: '#F9F9F9', 
              borderRadius: '5px',
              marginBottom: '20px',
              borderLeft: '4px solid #D32F2F'
            }}>
              <p style={{ margin: 0, fontSize: '14px', color: '#555' }}>
                A verification code has been sent to <strong>{email}</strong>. 
                Please check your inbox and enter the 6-digit code below to confirm account deletion.
              </p>
            </div>
            
            <div style={{ marginBottom: '25px' }}>
              <label style={{
                display: 'block',
                marginBottom: '8px',
                fontWeight: '500',
                color: '#333'
              }}>Verification Code</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                placeholder="Enter 6-digit code"
                style={{
                  width: '100%',
                  padding: '12px 15px',
                  borderRadius: '4px',
                  border: '1px solid #ddd',
                  fontSize: '16px',
                  letterSpacing: '2px',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
                maxLength={6}
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              style={{
                width: '100%',
                padding: '14px',
                backgroundColor: "#D32F2F",
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                fontSize: '16px',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {loading ? (
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <svg style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '10px',
                    animation: 'spin 1s linear infinite'
                  }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </span>
              ) : (
                "Confirm Account Deletion"
              )}
            </button>
          </form>
        )}

        <div style={{ 
          marginTop: '25px',
          textAlign: 'center'
        }}>
          <button
            onClick={() => navigate('/dashboard')}
            style={{
              background: 'none',
              border: 'none',
              color: '#666',
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            Cancel and Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountDelete;