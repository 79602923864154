import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'; 
import { AuthProvider } from './context/AuthContext';
import Register from './components/Register';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Support from './components/Support/Support';
import ViewSupport from './components/Support/ViewSupport';
import PrivateRoute from './Route/PrivateRoute';
import Sidebar from './components/Sidebar';
import AccountDelete from './components/AccountDelete';
import DeleteAppAccount from './components/DataApp/DeleteAppAccount';

// Dashboard and feature pages
import Dashboard from './pages/Dashboard/Dashboard';
import ProductManager from './pages/Item/Product';
import ExpenseTracker from './pages/Expense/ExpenseTracker';
import Sales from './pages/Sales/Sales';
import CreateKOT from './pages/Sales/Create-KOT';
import PrintKOT from './pages/Sales/PrintKOT';
import UnsavedChangesHandler from './pages/Sales/UnsavedChangesHandler';
import AcceptedOrders from './pages/AcceptedOrders/AcceptedOrders';
import KOT from './pages/KOT/KOT';
import PrintKOT2 from './pages/KOT/PrintKOT2';
import PrintKOT3 from './pages/Sales/PrintKOT3';
import TableManager from './pages/ManageTable/ManageTable';
import ExpenseReport from './pages/Report/ExpenseReport';
import ItemsSalesReport from './pages/Report/ItemsSalesReport';
import Settings from './pages/Settings/Settings';
import BillHistory from './pages/BillHistory/BillHistory';
import GenerateBill from './pages/GenerateBill/GenerateBill';
import GenerateBill2 from'./pages/GenerateBill/GenerateBill2';
import GenerateBill3 from './pages/GenerateBill/GenerateBill3';

// Landing Page components
import Navbar from './components/Navbar/Navbar';
import Home from './Page/Home/Home';
import Footer from './components/Footer/Footer';
import About from  './Page/About/About';
import Gallery from './Page/Gallery/Gallery';
import ContactUs from './Page/Contact/ContactUs';
import OurMenus from './Page/Menus/OurMenus';
import MenuItem from './Page/MenuItem/MenuItem';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Main />
      </Router>
    </AuthProvider>
  );
};

const Main = () => {
  const location = useLocation();
  
  // Routes that should have Navbar and Footer
  const navbarFooterRoutes = [
    "/", 
    "/about-us", 
    "/gallery", 
    "/contact", 
    "/our-menus",
    "/menu-item"
  ];
  
  // Check if current path should have Navbar and Footer
  const shouldShowNavbarFooter = navbarFooterRoutes.some(route => 
    location.pathname === route || 
    (route === "/menu-item" && location.pathname.startsWith("/menu-item/")) ||
    location.pathname.startsWith("/menu/")
  );
  
  // Public routes where sidebar should be hidden
  const publicRoutes = [
    "/login", 
    "/register", 
    "/forgot-password",
    "/support",
    "/DeleteAppAccount"
  ];
  
  // Check if current path is a public route (where sidebar is hidden)
  const isPublicRoute = publicRoutes.some(route => location.pathname === route) || shouldShowNavbarFooter;
  
  // Determine if support is being accessed publicly
  const isPublicSupport = location.pathname === "/support" && !location.search.includes("auth=true");
  
  // Only show sidebar on authenticated routes
  const shouldShowSidebar = !isPublicRoute && !isPublicSupport;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {shouldShowNavbarFooter && <Navbar />}
      
      <div className="flex flex-1">
        {shouldShowSidebar && <Sidebar />}
        <div className={`flex-1 min-h-screen ${!shouldShowSidebar ? 'w-full' : ''}`}>
          <Routes>
            {/* Public landing page routes with Navbar and Footer */}
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/gallery" element={<Gallery
              accessToken="IGQWROaEdMTW1CbHQybVBwYlk1eE5TWHRRQngwZAWNDa2ZAUWTFuWTMzb213NWR0WFZANcEJwWnI2d1ZArYjliazRyQlgxd3ZAES3hWZAlZAvbWxlRkswVk5ldzdhZAHFrcUs4MzVRazF3NW5GZAUtMVVhGbkFuOHlxRHlvaE0ZD"
              limit={21}
            />} />
            <Route path="/DeleteAppAccount" element={<DeleteAppAccount />} />
            <Route path="/contact" element={<ContactUs />} /> 
            <Route path="/our-menus" element={<OurMenus />} />
            <Route path="/menu/*" element={<OurMenus />} />
            <Route path="/menu-item/:id" element={<MenuItem />} />
            
            {/* Authentication routes */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            
            {/* Support route that can be accessed publicly or privately */}
            <Route path="/support" element={
              location.search.includes("auth=true") ? 
              <PrivateRoute><Support /></PrivateRoute> : 
              <Support />
            } />
            
            {/* Private routes */}
            <Route path="/View-Support" element={<PrivateRoute><ViewSupport /></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/product" element={<PrivateRoute><ProductManager /></PrivateRoute>} />
            <Route path="/Expense" element={<PrivateRoute><ExpenseTracker /></PrivateRoute>} />
            <Route path="/sales" element={<PrivateRoute><Sales /></PrivateRoute>} />
            <Route path='/create-kot' element={<PrivateRoute><CreateKOT /></PrivateRoute>} />
            <Route path="/print-kot" element={<PrivateRoute><PrintKOT /></PrivateRoute>} />
            <Route path="/unsaved-changes-handler" element={<PrivateRoute><UnsavedChangesHandler /></PrivateRoute>} />
            <Route path="/accepted-orders" element={<PrivateRoute><AcceptedOrders /></PrivateRoute>} />
            <Route path="/kot" element={<PrivateRoute><KOT /></PrivateRoute>} />
            <Route path="/print-kot2" element={<PrivateRoute><PrintKOT2 /></PrivateRoute>} />
            <Route path="/print-kot3" element={<PrivateRoute><PrintKOT3 /></PrivateRoute>} />
            <Route path="/table-manager" element={<PrivateRoute><TableManager /></PrivateRoute>} />
            <Route path='/expense-report'element={<PrivateRoute><ExpenseReport /></PrivateRoute>} />
            <Route path='/items-sales-report'element={<PrivateRoute><ItemsSalesReport /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/AccountDelete" element={<PrivateRoute><AccountDelete /></PrivateRoute>} />
            <Route path="/generate-bill/:orderId" element={<PrivateRoute><GenerateBill /></PrivateRoute>} />
            <Route path="/generate-bill-2/:billId" element={<PrivateRoute><GenerateBill2 /></PrivateRoute>} />
            <Route path="/generate-bill-3/:billId" element={<PrivateRoute><GenerateBill3 /></PrivateRoute>} />
            <Route path="/bill-history" element={<PrivateRoute><BillHistory /></PrivateRoute>} />
          
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
      
      {shouldShowNavbarFooter && <Footer />}
    </div>
  );
};

export default App;
