import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Printer } from 'lucide-react';

const PrintKOT2 = () => {
  const location = useLocation();
  const { orderInfo, items } = location.state || {};
  const [currentTime] = useState(new Date());

  if (!orderInfo || !items) {
    return <div className="text-base text-center p-4">No KOT information found</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="p-4">
      {/* Print Button */}
      <div className="print:hidden mb-4 flex justify-center">
        <button 
          onClick={handlePrint} 
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 text-base"
        >
          <Printer className="mr-2" size={18} />
          Print KOT
        </button>
      </div>

      {/* KOT Content - This will be visible when printing */}
      <div className="w-64 mx-auto bg-white p-4 print:p-2">
        <div className="text-center mb-4">
          <div className="text-xl font-bold mb-2">*** KOT ***</div>
          <div className="text-base mb-1">Table: {orderInfo.selectedTable}</div>
          <div className="text-base mb-1">Order #{orderInfo.orderId}</div>
          <div className="text-base mb-1">Date: {currentTime.toLocaleDateString()}</div>
          <div className="text-base mb-1">Time: {currentTime.toLocaleTimeString()}</div>
        </div>

        <div className="border-t-2 border-b-2 border-gray-300 my-3"></div>

        {/* Items */}
        <div className="space-y-2">
          {items.map((item, index) => (
            <div key={index} className="flex justify-between text-base">
              <div className="w-10 text-center">{item.quantity}</div>
              <div className="flex-1 ml-2">{item.name}</div>
            </div>
          ))}
        </div>

        <div className="border-t-2 border-gray-300 my-3"></div>

        <div className="text-center mt-4">
          <p className="text-base">*** End of KOT ***</p>
        </div>
      </div>

      <style>
        {`
          @media print {
            @page {
              size: 80mm auto;
              margin: 0;
            }

            body {
              margin: 0;
              padding: 0;
              width: 80mm;
            }

            .print\\:hidden {
              display: none !important;
            }

            .mx-auto {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }

            .text-base {
              font-size: 14px !important;
            }

            .text-xl {
              font-size: 18px !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PrintKOT2;