import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { api } from "../api/api";
import '../styles/UnifiedAuth.css';
import Toast from './Toast';

const Register = () => {
  const [name, setName] = useState("");
  const [restaurantNAME, setrestaurantNAME] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const response = await api("/auth/register", "POST", {
        username: name,
        email,
        restaurantNAME,
        password,
      });
      setToastMessage("Registration successful! Please verify your email.");
      setShowToast(true);
      setShowOTPInput(true);
    } catch (err) {
      setError(err.message || "Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const response = await api("/auth/verify-registration-otp", "POST", {
        email,
        otp,
      });
      login(response.token);
      setToastMessage("Email verified successfully! Redirecting to login...");
      setShowToast(true);
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (err) {
      setError(err.message || "OTP verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="unified-auth-container">
     {showToast && (
  <Toast 
    message={toastMessage}
    type="success"
    onClose={() => setShowToast(false)}
  />
)}
      
      <div className="auth-form-wrapper">
        <h2 style={{ 
          fontSize: '24px', 
          fontWeight: 'bold', 
          marginBottom: '20px',
          color: '#8B4513'
        }}>
          {showOTPInput ? 'Verify Email' : 'Create Account'}
        </h2>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        {!showOTPInput ? (
          <form onSubmit={handleSubmit}>
         <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Restaurant Name</label>
              <input
                type="text"
                value={restaurantNAME}
                onChange={(e) => setrestaurantNAME(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="submit-btn"
              disabled={loading}
            >
              {loading ? "Creating Account..." : "Register"}
            </button>
          </form>
        ) : (
          <form onSubmit={handleVerifyOTP}>
            <div className="form-group">
              <label>Enter Verification Code</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                required
                placeholder="Enter 6-digit code"
              />
            </div>

            <button
              type="submit"
              className="submit-btn"
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify Email"}
            </button>
          </form>
        )}

        <div className="auth-links">
          <span onClick={() => navigate('/login')}>
            Already have an account? Sign in here
          </span>
        </div>
      </div>
    </div>
  );
};

export default Register;