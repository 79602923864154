import React, { useState } from 'react';
import axios from 'axios';
import './DeleteAccount.css';

const DeleteAccount = () => {
  const [email, setEmail] = useState('');
  const [restaurantId, setRestaurantId] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleRequestDeletion = () => {
    if (!email || !password || !restaurantId) {
      setMessage('Please fill in all fields');
      setIsError(true);
      return;
    }
    setShowConfirmation(true);
  };

  const handleCancelDeletion = () => {
    setShowConfirmation(false);
  };

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      setMessage('');
      setIsError(false);
      setShowConfirmation(false);
  
      const response = await axios.post(
        'http://13.239.200.245:8000/api/account/delete',
        {
          email,
          restaurantId,
          password,
          otp: showOtpInput ? otp : undefined,
        }
      );
  
      if (response.data.success) {
        if (response.data.message === 'OTP sent to your email for account deletion') {
          setShowOtpInput(true);
          setMessage('OTP sent to your email for verification');
          setIsSuccess(true);
        } else {
          setMessage('Account deleted successfully');
          setIsSuccess(true);
          setTimeout(() => {
            window.location.href = '/login';
          }, 2000);
        }
      }
    } catch (error) {
      setMessage(
        error.response?.data?.message || 'An error occurred during account deletion'
      );
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="delete-account-container">
      <div className="delete-account-card">
        <div className="header">
          <h1 className="delete-account-title">Delete Account</h1>
          <div className="warning-box">
            <div className="warning-icon">⚠️</div>
            <div className="warning-text">
              <strong>Warning:</strong> This action cannot be undone.
            </div>
          </div>
        </div>

        {message && (
          <div className={`message-box ${isError ? 'error' : 'success'}`}>
            {message}
          </div>
        )}

        <div className="form-container">
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              className="form-input"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="restaurantId">Restaurant ID</label>
            <input
              id="restaurantId"
              className="form-input"
              type="text"
              placeholder="Enter Restaurant ID"
              value={restaurantId}
              onChange={(e) => setRestaurantId(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              className="form-input"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {showOtpInput && (
            <div className="form-group otp-container">
              <label htmlFor="otp">One-Time Password (OTP)</label>
              <input
                id="otp"
                className="form-input"
                type="text"
                placeholder="Enter OTP from your email"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <p className="otp-hint">Please check your email for the verification code</p>
            </div>
          )}

          {!showOtpInput && !showConfirmation && (
            <button
              className="delete-button"
              onClick={handleRequestDeletion}
              disabled={loading}
            >
              Request Account Deletion
            </button>
          )}

          {showOtpInput && (
            <button
              className={`delete-button ${loading ? 'loading' : ''}`}
              onClick={handleDeleteAccount}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Verify & Delete Account'}
            </button>
          )}

          {showConfirmation && (
            <div className="confirmation-dialog">
              <div className="confirmation-content">
                <h2>Confirm Account Deletion</h2>
                <p>Are you sure you want to delete your account for Restaurant ID: <strong>{restaurantId}</strong>?</p>
                <p>This action cannot be undone and all data will be permanently lost.</p>
                <div className="confirmation-buttons">
                  <button className="cancel-button" onClick={handleCancelDeletion}>
                    Cancel
                  </button>
                  <button
                    className={`confirm-delete-button ${loading ? 'loading' : ''}`}
                    onClick={handleDeleteAccount}
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : 'Yes, Delete My Account'}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="alternative-options">
            <p>Not sure about deleting? <a href="/account/settings">Return to Account Settings</a></p>
            <p>Need help? <a href="/support">Contact Support</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
