// ItemSalesReport.js
import React, { useState, useEffect } from 'react';
import { BarChart, PieChart, Pie, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import { useAuth } from '../../context/AuthContext';
import { BarChartIcon, PieChartIcon } from 'lucide-react';

const ItemSalesReport = () => {
  const { token } = useAuth();
  const [sales, setSales] = useState([]);
  const [timeFilter, setTimeFilter] = useState('today');
  const [stats, setStats] = useState({
    totalRevenue: 0,
    totalOrders: 0,
    totalItems: 0,
    averageOrderValue: 0,
    paymentStats: {
      Cash: 0,
      UPI: 0,
      Card: 0
    }
  });
  const [topSellingItems, setTopSellingItems] = useState([]);
  const [chartType, setChartType] = useState('pie');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const COLORS = {
    bar: ['#4F46E5', '#7C3AED', '#EC4899', '#F59E0B', '#10B981', '#3B82F6', '#EF4444', '#6366F1', '#8B5CF6', '#14B8A6'],
    pie: ['#3B82F6', '#EF4444', '#10B981', '#F59E0B', '#6366F1', '#8B5CF6', '#EC4899', '#14B8A6', '#F97316', '#06B6D4']
  };

  const getDateRange = () => {
    const now = new Date();
    const startDate = new Date();
    const endDate = new Date();

    // Reset hours for consistent day boundaries
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    switch (timeFilter) {
      case 'today':
        break;
      case 'yesterday':
        startDate.setDate(now.getDate() - 1);
        endDate.setDate(now.getDate() - 1);
        break;
      case '7days':
        startDate.setDate(now.getDate() - 6);
        break;
      case '30days':
        startDate.setDate(now.getDate() - 29);
        break;
      case '3months':
        startDate.setMonth(now.getMonth() - 2);
        startDate.setDate(1);
        break;
      case '6months':
        startDate.setMonth(now.getMonth() - 5);
        startDate.setDate(1);
        break;
      case '1year':
        startDate.setFullYear(now.getFullYear() - 1);
        startDate.setDate(1);
        break;
      default:
        break;
    }

    return { startDate, endDate };
  };

  const mergeSalesData = (walkinData, savedData) => {
    const itemMap = new Map();
    let paymentTotals = {
      Cash: 0,
      UPI: 0,
      Card: 0
    };

    const processItems = (orders, source) => {
      orders.forEach(order => {
        const orderDate = new Date(order.createdAt);
        const { startDate, endDate } = getDateRange();

        if (orderDate >= startDate && orderDate <= endDate) {
          if (source === 'walkin' && order.paymentMode) {
            paymentTotals[order.paymentMode] += order.totalAmount || 0;
          }

          order.items.forEach(item => {
            const itemName = source === 'walkin' ? item.itemName : item.name;
            const quantity = item.quantity || 0;
            const revenue = source === 'walkin' ? item.totalPrice : (item.price * item.quantity);

            if (itemMap.has(itemName)) {
              const existing = itemMap.get(itemName);
              itemMap.set(itemName, {
                name: itemName,
                quantity: existing.quantity + quantity,
                revenue: existing.revenue + revenue,
                orders: existing.orders + 1
              });
            } else {
              itemMap.set(itemName, {
                name: itemName,
                quantity: quantity,
                revenue: revenue,
                orders: 1
              });
            }
          });
        }
      });
    };

    processItems(walkinData, 'walkin');
    processItems(savedData, 'saved');

    return {
      items: Array.from(itemMap.values()).map(item => ({
        itemName: item.name,
        totalQuantity: item.quantity,
        totalRevenue: item.revenue,
        orderCount: item.orders
      })),
      paymentTotals
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const { startDate, endDate } = getDateRange();

        const [walkinRes, savedRes] = await Promise.all([
          fetch(`https://www.wazwanrestaurants.com/api/walkin-orders?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          fetch(`https://www.wazwanrestaurants.com/api/saved-orders?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          })
        ]);

        if (!walkinRes.ok || !savedRes.ok) {
          throw new Error('Failed to fetch data');
        }

        const [walkinData, savedData] = await Promise.all([
          walkinRes.json(),
          savedRes.json()
        ]);

        const { items: combinedData, paymentTotals } = mergeSalesData(walkinData, savedData);
        setSales(combinedData);

        const sortedItems = [...combinedData]
          .sort((a, b) => b.totalQuantity - a.totalQuantity)
          .slice(0, 10);
        setTopSellingItems(sortedItems);

        const totals = combinedData.reduce((acc, item) => ({
          totalRevenue: acc.totalRevenue + item.totalRevenue,
          totalOrders: acc.totalOrders + item.orderCount,
          totalItems: acc.totalItems + item.totalQuantity
        }), { totalRevenue: 0, totalOrders: 0, totalItems: 0 });

        setStats({
          ...totals,
          averageOrderValue: totals.totalOrders ? totals.totalRevenue / totals.totalOrders : 0,
          paymentStats: paymentTotals
        });

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch sales data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeFilter, token]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border border-gray-200 rounded shadow">
          <p className="font-semibold">{label}</p>
          <p className="text-indigo-600">
            {payload[0].name}: {payload[0].value}
          </p>
          {payload[0].name === "Quantity Sold" && (
            <p className="text-green-600">
              Revenue: ₹{topSellingItems.find(item => item.itemName === label)?.totalRevenue.toFixed(2)}
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  const renderChart = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-96">
          <p className="text-gray-500">Loading...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center h-96">
          <p className="text-red-500">{error}</p>
        </div>
      );
    }

    if (topSellingItems.length === 0) {
      return (
        <div className="flex items-center justify-center h-96">
          <p className="text-gray-500">No data available for selected period</p>
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={500}>
        {chartType === 'bar' ? (
          <BarChart
            data={topSellingItems}
            margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="itemName"
              interval={0}
              tick={props => (
                <g transform={`translate(${props.x},${props.y})`}>
                  <text
                    x={0}
                    y={0}
                    dy={16}
                    textAnchor="end"
                    fill="#666"
                    transform="rotate(-45)"
                    fontSize={12}
                  >
                    {props.payload.value}
                  </text>
                </g>
              )}
              height={60}
            />
            <YAxis
              label={{ 
                value: 'Quantity Sold', 
                angle: -90, 
                position: 'insideLeft',
                offset: -5
              }}
              allowDecimals={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="totalQuantity" name="Quantity Sold" maxBarSize={60}>
              {topSellingItems.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS.bar[index % COLORS.bar.length]} />
              ))}
            </Bar>
          </BarChart>
        ) : (
          <PieChart>
            <Pie
              data={topSellingItems}
              cx="50%"
              cy="50%"
              outerRadius="65%"
              dataKey="totalRevenue"
              nameKey="itemName"
              label={({ cx, cy, midAngle, outerRadius, value, name }) => {
                const RADIAN = Math.PI / 180;
                const radius = outerRadius * 1.2;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                const percent = ((value / topSellingItems.reduce((sum, item) => sum + item.totalRevenue, 0)) * 100).toFixed(1);

                return (
                  <text
                    x={x}
                    y={y}
                    fill="#374151"
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                    fontSize={12}
                  >
                    {`${name} (${percent}%)`}
                  </text>
                );
              }}
            >
              {topSellingItems.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS.pie[index % COLORS.pie.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend 
              verticalAlign="bottom" 
              height={36}
              formatter={(value, entry) => {
                const item = topSellingItems.find(item => item.itemName === value);
                return `${value} - ₹${item.totalRevenue.toFixed(2)}`;
              }}
            />
          </PieChart>
        )}
      </ResponsiveContainer>
    );
  };

  return (
    <div className="p-4 md:p-6 bg-gray-50 min-h-screen">
      <div className="mb-6 space-y-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">Item Sales Report</h1>
          <div className="flex gap-2">
            {['pie', 'bar'].map(type => (
              <button
                key={type}
                onClick={() => setChartType(type)}
                className={`p-2 rounded ${
                  chartType === type ? 'bg-indigo-600 text-white' : 'bg-white text-gray-600'
                }`}
              >
                {type === 'pie' ? <PieChartIcon size={20}/> : <BarChartIcon size={20} />}
              </button>
            ))}
          </div>
        </div>
        
        <div className="flex flex-wrap gap-2">
          {['today', 'yesterday', '7days', '30days', '3months', '6months', '1year'].map(filter => (
            <button
              key={filter}
              onClick={() => setTimeFilter(filter)}
              className={`px-3 py-1.5 text-sm md:px-4 md:py-2 rounded-lg ${
                timeFilter === filter
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {[
            { label: "Total Revenue", value: `₹${stats.totalRevenue?.toFixed(2) || 0}`, color: "from-indigo-500 to-indigo-600" },
            { label: "Average Order Value", value: `₹${stats.averageOrderValue?.toFixed(2) || 0}`, color: "from-emerald-500 to-emerald-600" },
            { label: "Total Orders", value: stats.totalOrders || 0, color: "from-amber-500 to-amber-600" },
            { label: "Total Items Sold", value: stats.totalItems || 0, color: "from-purple-500 to-purple-600" }
          ].map(stat => (
            <div key={stat.label} className={`p-4 md:p-6 rounded-lg bg-gradient-to-br ${stat.color} text-white`}>
              <p className="text-sm opacity-80">{stat.label}</p>
              <p className="text-2xl md:text-3xl font-bold">{stat.value}</p>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {[
            { label: "Cash Payments", value: stats.paymentStats.Cash, color: "from-green-500 to-green-600" },
            { label: "UPI Payments", value: stats.paymentStats.UPI, color: "from-blue-500 to-blue-600" },
            { label: "Card Payments", value: stats.paymentStats.Card, color: "from-red-500 to-red-600" }
          ].map(stat => (
            <div key={stat.label} className={`p-4 md:p-6 rounded-lg bg-gradient-to-br ${stat.color} text-white`}>
              <p className="text-sm opacity-80">{stat.label}</p>
              <p className="text-2xl md:text-3xl font-bold">₹{stat.value.toFixed(2)}</p>
            </div>
          ))}
        </div>

        <div className="bg-white p-4 md:p-8 rounded-lg shadow">
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800">
              {chartType === 'pie' ? 'Revenue Distribution' : 'Top Selling Items'}
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              {timeFilter === 'today' ? 'Today\'s' : 
               timeFilter === 'yesterday' ? 'Yesterday\'s' : 
               `Last ${timeFilter}`} performance
            </p>
          </div>

          {loading ? (
            <div className="flex items-center justify-center h-96">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
            </div>
          ) : error ? (
            <div className="flex flex-col items-center justify-center h-96 text-center">
              <p className="text-red-500 font-medium mb-2">{error}</p>
              <button 
                onClick={() => window.location.reload()}
                className="text-indigo-600 hover:text-indigo-800 font-medium"
              >
                Try Again
              </button>
            </div>
          ) : topSellingItems.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-96 text-center">
              <p className="text-gray-500 font-medium mb-2">No data available for the selected period</p>
              <p className="text-sm text-gray-400">Try selecting a different time range</p>
            </div>
          ) : (
            <>
              {renderChart()}
              
              {/* Data Table */}
              <div className="mt-8 overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Item Name
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Quantity Sold
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Revenue
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Orders
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {topSellingItems.map((item, index) => (
                      <tr key={item.itemName} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.itemName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                          {item.totalQuantity}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                          ₹{item.totalRevenue.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                          {item.orderCount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="bg-gray-50">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900 font-medium">
                        {topSellingItems.reduce((sum, item) => sum + item.totalQuantity, 0)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900 font-medium">
                        ₹{topSellingItems.reduce((sum, item) => sum + item.totalRevenue, 0).toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900 font-medium">
                        {topSellingItems.reduce((sum, item) => sum + item.orderCount, 0)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemSalesReport;