import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const UnsavedChangesHandler = ({ hasUnsavedChanges, children }) => {
  const [showModal, setShowModal] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Handle browser close/reload
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    // Handle navigation events
    const handleLocationChange = () => {
      if (hasUnsavedChanges) {
        setShowModal(true);
        setIsNavigating(true);
        // Store the attempted navigation path
        setPendingNavigation(location.pathname);
        // Prevent immediate navigation
        return false;
      }
      return true;
    };

    // Add event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleLocationChange);

    // Cleanup function
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [hasUnsavedChanges, location]);

  const handleStay = () => {
    setShowModal(false);
    setIsNavigating(false);
    setPendingNavigation(null);
    // Stay on current page
    navigate(location.pathname);
  };

  const handleLeave = () => {
    setShowModal(false);
    setIsNavigating(false);
    // Allow the navigation to proceed
    window.onbeforeunload = null;
    
    if (pendingNavigation) {
      navigate(pendingNavigation);
    }
    setPendingNavigation(null);
  };

  // Function to handle attempted navigation
  const handleNavigationAttempt = (path) => {
    if (hasUnsavedChanges) {
      setShowModal(true);
      setIsNavigating(true);
      setPendingNavigation(path);
      return false;
    }
    return true;
  };

  return (
    <>
      {children}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full mx-4 transform transition-all ease-in-out duration-300 scale-100">
            <div className="mb-4">
              <h2 className="text-xl font-semibold text-gray-800">
                Unsaved Changes
              </h2>
            </div>
            <div className="mb-6">
              <p className="text-gray-600">
                You have unsaved changes. Are you sure you want to leave? Your changes will be lost.
              </p>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleStay}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors duration-200"
              >
                Stay
              </button>
              <button
                onClick={handleLeave}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200"
              >
                Leave
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnsavedChangesHandler;