import React, { useState, useEffect } from 'react';
import { Search, Filter, RefreshCcw, AlertCircle, CheckCircle, Clock, User, Mail, MessageCircle, X } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';


const ViewTicket = () => {
  const { token, userEmail } = useAuth();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    status: 'all',
    sortBy: 'newest'
  });
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);


  const fetchTickets = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://www.wazwanrestaurants.com/api/support/view`);
      if (!response.ok) throw new Error('Failed to fetch tickets');
      const data = await response.json();
      // Filter tickets based on the user's email
      const userTickets = data.data.filter(ticket => ticket.email === userEmail);
      setTickets(userTickets);
      handleFiltering(userTickets, filters);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const handleFiltering = (ticketsData, currentFilters) => {
    let filtered = [...ticketsData];

    // Apply search filter
    if (currentFilters.search) {
      const searchTerm = currentFilters.search.toLowerCase();
      filtered = filtered.filter(ticket =>
        ticket.email.toLowerCase().includes(searchTerm) ||
        ticket.name.toLowerCase().includes(searchTerm) ||
        ticket.message.toLowerCase().includes(searchTerm)
      );
    }

    // Apply status filter
    if (currentFilters.status !== 'all') {
      filtered = filtered.filter(ticket => ticket.status === currentFilters.status);
    }

    // Apply sorting
    filtered.sort((a, b) => {
      if (currentFilters.sortBy === 'newest') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (currentFilters.sortBy === 'oldest') {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      return 0;
    });

    setFilteredTickets(filtered);
  };

  useEffect(() => {
    fetchTickets();
  }, [userEmail]);

  useEffect(() => {
    handleFiltering(tickets, filters);
  }, [filters, tickets]);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <Clock className="h-4 w-4" />;
      case 'in-progress':
        return <RefreshCcw className="h-4 w-4" />;
      case 'resolved':
        return <CheckCircle className="h-4 w-4" />;
      default:
        return <AlertCircle className="h-4 w-4" />;
    }
  };

  const getStatusStyles = (status) => {
    const styles = {
      'pending': 'bg-gradient-to-r from-orange-500 to-orange-600 text-white',
      'in-progress': 'bg-gradient-to-r from-blue-500 to-blue-600 text-white',
      'resolved': 'bg-gradient-to-r from-green-500 to-green-600 text-white',
      'closed': 'bg-gradient-to-r from-gray-500 to-gray-600 text-white'
    };
    return styles[status] || 'bg-gradient-to-r from-gray-500 to-gray-600 text-white';
  };

  const fetchTicketDetails = async (ticketId) => {
    setDetailsLoading(true);
    setSelectedTicket(null); // Clear previous selection
    try {
      const token = localStorage.getItem('token'); // Retrieve the token from local storage
      const response = await fetch(`https://www.wazwanrestaurants.com/api/ticket/${ticketId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) throw new Error('Failed to fetch ticket details');
      const data = await response.json();
      setSelectedTicket(data.data); // Set the selected ticket data in state
    } catch (err) {
      setError(err.message); // Set error message if fetching fails
    } finally {
      setDetailsLoading(false); // Stop loading state
    }
  };


  return (
    <div className="bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Header Section */}
        <div className="bg-white rounded-2xl shadow-lg p-8 border border-gray-100">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                Support Tickets
              </h1>
              <p className="text-gray-600 mt-2">Track and manage your support requests efficiently</p>
            </div>
            <button
              onClick={fetchTickets}
              className="p-3 text-blue-600 hover:bg-blue-50 rounded-xl border border-blue-200 transition-all duration-200 hover:shadow-md"
            >
              <RefreshCcw className="h-5 w-5" />
            </button>
          </div>

          {/* Search and Filters */}
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="md:col-span-6">
              <div className="relative group">
                <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 transition-colors group-hover:text-blue-500" />
                <input
                  type="text"
                  placeholder="Search tickets..."
                  value={filters.search}
                  onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                  className="w-full pl-12 pr-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                />
              </div>
            </div>

            <div className="md:col-span-3">
              <select
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                className="w-full py-3 px-4 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              >
                <option value="all">All Status</option>
                <option value="pending">Pending</option>
                <option value="in-progress">In Progress</option>
                <option value="resolved">Resolved</option>
                <option value="closed">Closed</option>
              </select>
            </div>

            <div className="md:col-span-3">
              <select
                value={filters.sortBy}
                onChange={(e) => setFilters({ ...filters, sortBy: e.target.value })}
                className="w-full py-3 px-4 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              >
                <option value="newest">Newest First</option>
                <option value="oldest">Oldest First</option>
              </select>
            </div>
          </div>
        </div>

        {/* Error Alert */}
        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-6 rounded-xl shadow-sm">
            <div className="flex items-center gap-3 text-red-700">
              <AlertCircle className="h-6 w-6" />
              <p className="font-medium">{error}</p>
            </div>
          </div>
        )}

        {/* Loading State */}
        {loading && (
          <div className="bg-white rounded-2xl shadow-lg p-12 text-center">
            <div className="inline-block animate-spin rounded-full h-12 w-12 border-4 border-blue-100 border-t-blue-500"></div>
            <p className="text-gray-500 mt-4 font-medium">Loading your tickets...</p>
          </div>
        )}

        {/* Tickets List */}
        {!loading && !error && (
          <div className="bg-white rounded-2xl shadow-lg divide-y divide-gray-100">
            {filteredTickets.length === 0 ? (
              <div className="p-12 text-center">
                <MessageCircle className="h-16 w-16 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-500 text-lg font-medium">No tickets found</p>
              </div>
            ) : (
              filteredTickets.map((ticket) => (
                <div key={ticket._id} className="p-6 hover:bg-gray-50 transition-all duration-200">
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <div className="flex items-center gap-3 mb-3">
                        <div className="bg-blue-50 p-3 rounded-xl">
                          <User className="h-5 w-5 text-blue-600" />
                        </div>
                        <div>
                          <h3 className="font-semibold text-gray-900 text-lg">{ticket.name}</h3>
                          <div className="flex items-center gap-2 text-gray-600 text-sm mt-1">
                            <Mail className="h-4 w-4" />
                            <span>{ticket.email}</span>
                          </div>
                        </div>
                        <span className={`ml-auto px-4 py-1.5 rounded-full text-sm font-medium flex items-center gap-2 ${getStatusStyles(ticket.status)}`}>
                          {getStatusIcon(ticket.status)}
                          {ticket.status}
                        </span>
                      </div>

                      <div className="ml-14">
                        <p className="text-gray-700 font-medium">Subject: {ticket.subject}</p>
                        <p className="text-gray-500 text-sm mt-1">
                          Created: {new Date(ticket.createdAt).toLocaleString()}
                        </p>
                      </div>
                    </div>

                    <button
                      onClick={() => fetchTicketDetails(ticket._id)}
                      className="ml-4 bg-gradient-to-r from-blue-600 to-blue-700 text-white px-6 py-2.5 rounded-xl hover:shadow-lg transition-all duration-200 font-medium"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        {/* Ticket Details Modal */}
        {selectedTicket && (
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
            <div className="bg-white rounded-2xl shadow-xl p-8 max-w-lg w-full animate-fade-in">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-gray-800">Ticket Details</h2>
                <button
                  onClick={() => setSelectedTicket(null)}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                >
                  <X className="h-6 w-6 text-gray-500" />
                </button>
              </div>

              {detailsLoading ? (
                <div className="text-center py-8">
                  <div className="inline-block animate-spin rounded-full h-12 w-12 border-4 border-blue-200 border-t-blue-600"></div>
                  <p className="text-gray-500 mt-4 font-medium">Loading ticket details...</p>
                </div>
              ) : (
                <div className="space-y-6">
                  <div className="bg-gray-50 p-6 rounded-xl space-y-4">
                    <div className="flex justify-between items-center">
                      <span className="font-semibold text-gray-700">Status</span>
                      <span className={`px-4 py-1.5 rounded-full text-sm font-medium flex items-center gap-2 ${getStatusStyles(selectedTicket.status)}`}>
                        {getStatusIcon(selectedTicket.status)}
                        {selectedTicket.status}
                      </span>
                    </div>

                    <div className="space-y-3">
                      <div className="flex items-center gap-2">
                        <User className="h-5 w-5 text-gray-400" />
                        <span className="font-medium text-gray-700">{selectedTicket.name}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Mail className="h-5 w-5 text-gray-400" />
                        <span className="text-gray-600">{selectedTicket.email}</span>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <h3 className="font-semibold text-gray-700 mb-2">Subject</h3>
                      <p className="text-gray-600 bg-gray-50 p-4 rounded-xl">{selectedTicket.subject}</p>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-700 mb-2">Message</h3>
                      <p className="text-gray-600 bg-gray-50 p-4 rounded-xl whitespace-pre-wrap">{selectedTicket.message}</p>
                    </div>
                    <div className="text-sm text-gray-500">
                      Created: {new Date(selectedTicket.createdAt).toLocaleString()}
                    </div>
                  </div>

                  <button
                    onClick={() => setSelectedTicket(null)}
                    className="w-full bg-gradient-to-r from-red-600 to-red-700 text-white px-6 py-3 rounded-xl hover:shadow-lg transition-all duration-200 font-medium mt-6"
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewTicket;
