import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Mail, Phone, Send,
  Clock,  HelpCircle, Linkedin,
  Ticket, ChevronDown, 
} from 'lucide-react';

const Support = () => {
  const [searchParams] = useSearchParams();
  const isAuthenticated = searchParams.get('auth') === 'true';

  const [activeTab, setActiveTab] = useState('newTicket');
  const [expandedFaqs, setExpandedFaqs] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    priority: 'normal'
  });
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('https://www.wazwanrestaurants.com/api/support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        showNotification('Ticket submitted successfully!', 'success');
        setFormData({ name: '', email: '', subject: '', message: '', priority: 'normal' });
        fetchTickets();
      } else {
        showNotification('Failed to submit ticket. Please try again.', 'error');
      }
    } catch (error) {
      showNotification('An error occurred. Please try again.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };


  useEffect(() => {
    fetchTickets();
  }, []);




  const fetchTickets = async () => {
    try {
      const response = await fetch('https://www.wazwanrestaurants.com/api/support/view');
      if (response.ok) {
        const data = await response.json();
        setTickets(data.data);
      }
    } catch (error) {
      console.error('Error fetching tickets:', error);
      showNotification('Failed to fetch tickets', 'error');
    }
  };

  const showNotification = (message, type) => {
    setNotification({ show: true, message, type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: '' });
    }, 3000);
  };



  const recentTickets = [
    { id: 1, subject: "Login Issue", status: "open", priority: "high" },
    { id: 2, subject: "Feature Request", status: "in-progress", priority: "normal" },
    { id: 3, subject: "Billing Question", status: "resolved", priority: "low" }
  ];

  const faqs = [
    {
      question: "How do I reset my password?",
      answer: "You can reset your password by clicking the 'Forgot Password' link on the login page. Follow the instructions sent to your email."
    },
    {
      question: "What are your support hours?",
      answer: "Our support team is available 24/7 to assist you with any questions or concerns."
    },
    {
      question: "How do I update my account information?",
      answer: "Go to Settings in your dashboard and click on 'Profile' to update your account details."
    }
  ];


  const toggleFaq = (index) => {
    setExpandedFaqs(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };


  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-4 md:p-8">
      {/* Success Notification */}
      {notification.show && (
        <div className={`fixed top-4 right-4 px-6 py-3 rounded-lg shadow-lg z-50 ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white font-medium`}>
          {notification.message}
        </div>
      )}

      {/* Header Section */}
      <div className="max-w-7xl mx-auto mb-8">
        <div className='text-right text-xl font-bold text-gray-800 mb-4'>
          <Clock className="inline-block mr-2  h-6 w-6 text-blue-500" />
          <span>24/7 Support</span>
        </div>
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">How can we help you?</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Our support team is here to assist you. Choose from the options below or submit a new support ticket.
          </p>
        </div>

        {/* Quick Contact Info */}
        <div className="grid md:grid-cols-3 gap-4 mb-8">
          {[
            {
              icon: Mail,
              title: 'Email Support',
              info: 'contact.wazwan@gmail.com',
              href: 'mailto:contact.wazwan@gmail.com'
            },
            {
              icon: Phone,
              title: 'Phone Support',
              info: '+91 8077057743',
              href: 'tel:+918077057743'
            },
            {
              icon: Linkedin,
              title: 'LinkedIn',
              info: 'https://www.linkedin.com',
              href: 'https://in.linkedin.com/company/wazwan-restaurant'
            },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <a
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-4"
              >
                <div className="bg-blue-100 p-3 rounded-lg">
                  <item.icon className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-800">{item.title}</h3>
                  <p className="text-gray-600">{item.info}</p>
                </div>
              </a>
            </div>
          ))}
        </div>


        {/* Main Content Tabs */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="border-b border-gray-200 mb-6">
            <div className="flex space-x-6">
              {[
                { id: 'newTicket', label: 'New Ticket', icon: Ticket },
                { id: 'faq', label: 'FAQs', icon: HelpCircle }
              ].map((tab) => (
                (!tab.authRequired || isAuthenticated) && (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`pb-4 flex items-center space-x-2 ${activeTab === tab.id
                      ? 'border-b-2 border-blue-500 text-blue-600'
                      : 'text-gray-500 hover:text-gray-700'
                      }`}
                  >
                    <tab.icon className="h-5 w-5" />
                    <span>{tab.label}</span>
                  </button>
                )
              ))}
            </div>
          </div>

          {/* Tab Contents */}
          {/* Tab Contents */}
          <div className="mt-6">
            {activeTab === 'newTicket' && (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Subject</label>
                  <select
                    value={formData.subject}
                    onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                    disabled={isSubmitting}
                  >
                    <option value="" disabled>Select a subject</option>
                    <option value="Technical Support">Technical Support</option>
                    <option value="Billing Inquiry">Billing Inquiry</option>
                    <option value="General Question">General Question</option>
                    <option value="Feedback">Feedback</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32"
                    required
                    disabled={isSubmitting}
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? (
                      <>
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                        <span>Submitting...</span>
                      </>
                    ) : (
                      <>
                        <Send className="h-5 w-5" />
                        <span>Submit Ticket</span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            )}

            <div className="mt-6">
              {activeTab === 'faq' && (
                <div className="space-y-4">
                  {faqs.map((faq, index) => (
                    <div
                      key={index}
                      className="border border-gray-200 rounded-lg overflow-hidden hover:border-blue-300 transition-colors duration-300"
                    >
                      <button
                        onClick={() => toggleFaq(index)}
                        className="w-full p-4 flex items-center justify-between font-medium text-gray-800 hover:bg-blue-50 transition-colors duration-300"
                      >
                        <div className="flex items-center">
                          <HelpCircle className="h-5 w-5 text-blue-500 mr-2" />
                          <span className="text-left">{faq.question}</span>
                        </div>
                        <div className={`transform transition-transform duration-300 ${expandedFaqs[index] ? 'rotate-180' : 'rotate-0'
                          }`}>
                          <ChevronDown className="h-5 w-5 text-gray-500" />
                        </div>
                      </button>
                      <div className={`
                      grid transition-all duration-300 ease-in-out
                      ${expandedFaqs[index] ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}
                    `}>
                        <div className="overflow-hidden">
                          <div className={`
                          p-4 pt-0 text-gray-600 bg-gray-50
                          transform transition-all duration-300
                          ${expandedFaqs[index] ? 'translate-y-0' : '-translate-y-2'}
                        `}>
                            <p className="ml-7">{faq.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Support;