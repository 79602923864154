import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Printer } from 'lucide-react';

const GenerateBill2 = () => {
  const { billId } = useParams();
  const { token } = useAuth();
  const [currentTime] = useState(new Date());
  const [bill, setBill] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [billResponse, settingsResponse] = await Promise.all([
          fetch(`https://www.wazwanrestaurants.com/api/walkin-orders/${billId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }),
          fetch('https://www.wazwanrestaurants.com/api/settings', {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
        ]);

        if (!billResponse.ok || !settingsResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const [billData, settingsData] = await Promise.all([
          billResponse.json(),
          settingsResponse.json()
        ]);

        setBill(billData);
        setSettings(settingsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message || 'Failed to load bill information');
        setLoading(false);
      }
    };

    if (token && billId) {
      fetchData();
    }
  }, [billId, token]);

  const handlePrint = () => {
    window.print();
  };

  if (!token) return <div className="text-base text-center p-4">Please log in</div>;
  if (loading) return <div className="text-base text-center p-4">Loading...</div>;
  if (error) return <div className="text-base text-center p-4">{error}</div>;
  if (!bill || !settings) return <div className="text-base text-center p-4">No bill found</div>;

  return (
    <div className="w-64 mx-auto text-sm">
      {/* Print Button */}
      <div className="print:hidden mb-4">
        <button onClick={handlePrint} className="flex items-center px-4 py-2 bg-blue-500 text-white rounded text-base">
          <Printer className="mr-2" size={18} />
          Print
        </button>
      </div>

      {/* Bill Content */}
      <div className="text-center mb-4">
        <div className="text-xl font-bold mb-2">{settings.restaurantName}</div>
        <div className="text-base mb-1">{settings.location}</div>
        <div className="text-base mb-1">Ph: {settings.phoneNumber}</div>
        {settings.gstin && <div className="text-base mb-1">GSTIN: {settings.gstin}</div>}
      </div>

      <div className="text-base mb-4">
        {bill.customerName && <div className="mb-1">Customer Name: {bill.customerName}</div>}
        <div className="mb-1">Bill #: {bill.orderId}</div>
        <div className="mb-1">Date: {currentTime.toLocaleDateString()}</div>
        <div className="mb-1">Table: {bill.selectedTable}</div>
        <div className="mb-1">Payment Mode: {bill.paymentMode}</div>
      </div>

      <div className="border-t-2 border-b-2 border-gray-300 my-3"></div>

      {/* Items */}
      <div className="mb-4">
        {bill.items.map((item, index) => (
          <div key={index} className="mb-2">
            <div className="flex text-base justify-between">
              <div className="w-32 truncate">{item.itemName}</div>
              <div className="text-right">
                <div>{item.quantity}x₹{item.price.toFixed(2)}</div>
                <div>₹{item.totalPrice.toFixed(2)}</div>
              </div>
            </div>
            {item.discountPercentage > 0 && (
              <div className="text-sm text-right text-gray-600">
                Discount: {item.discountPercentage}% off
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="border-t-2 border-gray-300 my-3"></div>

      {/* Bill Summary */}
      <div className="text-base">
        <div className="flex justify-between mb-1">
          <span>Subtotal:</span>
          <span>₹{bill.subTotal.toFixed(2)}</span>
        </div>

        {bill.discountPercentage > 0 && (
          <>
            <div className="flex justify-between mb-1 text-gray-600">
              <span>Discount ({bill.discountPercentage}%):</span>
              <span>-₹{bill.discountAmount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mb-1">
              <span>After Discount:</span>
              <span>₹{(bill.subTotal - bill.discountAmount).toFixed(2)}</span>
            </div>
          </>
        )}

        <div className="flex justify-between mb-1">
          <span>SGST:</span>
          <span>₹{bill.sgst.toFixed(2)}</span>
        </div>
        <div className="flex justify-between mb-1">
          <span>CGST:</span>
          <span>₹{bill.cgst.toFixed(2)}</span>
        </div>
        <div className="flex justify-between font-bold border-t-2 border-gray-300 mt-2 pt-2 text-lg">
          <span>Total:</span>
          <span>₹{bill.totalAmount.toFixed(2)}</span>
        </div>
      </div>

      <div className="text-center mt-4 text-base">
        <p>{settings.note || 'Thank you!'}</p>
      </div>

      <style>
        {`@media print {
            @page {
              size: 80mm auto;
              margin: 0;
            }
            body {
              width: 80mm;
              padding: 5mm;
            }
            .print\\:hidden {
              display: none !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default GenerateBill2;