import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Printer, ArrowLeft, ShoppingBag, BadgeIndianRupee, PrinterCheck,UserPlus } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import UnsavedChangesHandler from './UnsavedChangesHandler';

const NewSales = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [tables, setTables] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('Cash');
  const [selectedTable, setSelectedTable] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [view, setView] = useState('tables');
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [heldBills, setHeldBills] = useState({});
  const [selectedBill, setSelectedBill] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [showVariantModal, setShowVariantModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [showCustomerModal, setShowCustomerModal] = useState(false);


  const categories = [
    'All',
    'Beverage',
    'Starter',
    'Dessert',
    'Breads',
    'Main-Course',
    'Combo',
    'Sweets',
    'Snacks',
    'Custom'
  ];

  useEffect(() => {
    fetchTables();
    fetchAllHeldBills();
  }, []);

  useEffect(() => {
    if (view === 'menu') {
      fetchProducts();
      if (selectedTable) {
        fetchHeldBill(selectedTable._id);
      }
    }
  }, [view, selectedTable]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      setHasUnsavedChanges(true);
    }
  }, [selectedItems]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://www.wazwanrestaurants.com/api/products', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch products');
      const data = await response.json();
      const formattedProducts = data.map(product => ({
        _id: product._id,
        itemName: product.itemName,
        totalPrice: product.basePrice,
        imageUrl: product.imageUrl,
        type: product.type,
        variants: product.variants || []
      }));
      setProducts(formattedProducts);
    } catch (err) {
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  const fetchAllHeldBills = async () => {
    try {
      const response = await fetch('https://www.wazwanrestaurants.com/api/held-bills', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch held bills');
      const bills = await response.json();
      
      const billsObject = bills.reduce((acc, bill) => {
        acc[bill.tableId] = bill.items;
        return acc;
      }, {});
      
      setHeldBills(billsObject);
    } catch (err) {
      setError('Failed to fetch held bills');
    }
  };

  const fetchHeldBill = async (tableId) => {
    try {
      const response = await fetch(`https://www.wazwanrestaurants.com/api/held-bills/table/${tableId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const bill = await response.json();
        if (bill) {
          // Transform the items to maintain correct price structure
          const transformedItems = bill.items.map(item => ({
            _id: item.itemId,
            itemName: item.itemName,
            quantity: item.quantity,
            variant: item.variant,
            totalPrice: item.totalPrice, // This is already the unit price
          }));
          
          setSelectedItems(transformedItems);
          setSelectedBill(bill);
          setHasUnsavedChanges(false);
        } else {
          setSelectedItems([]);
          setSelectedBill(null);
        }
      } else {
        setSelectedItems([]);
        setSelectedBill(null);
      }
    } catch (err) {
      console.error('Failed to fetch held bill:', err);
      setError('Failed to fetch held bill');
      setSelectedItems([]);
      setSelectedBill(null);
    }
  };

  const fetchTables = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://www.wazwanrestaurants.com/api/tables', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch tables');
      const data = await response.json();
      const formattedTables = data.map(table => ({
        _id: table._id,
        name: `Table ${table.tableNumber}`,
        tableNumber: table.tableNumber,
        status: table.status || 'Available'
      }));
      setTables(formattedTables);
    } catch (err) {
      setError('Failed to fetch tables');
    } finally {
      setLoading(false);
    }
  };

  const saveWalkInOrder = async () => {
    try {
      setLoading(true);
      
      const subTotal = selectedItems.reduce((total, item) => 
        total + (item.totalPrice * item.quantity), 0
      );
      const discountAmount = subTotal * (discount / 100);
      const afterDiscountTotal = subTotal - discountAmount;
      
      const sgst = afterDiscountTotal * 0.025;
      const cgst = afterDiscountTotal * 0.025;
      const totalAmount = afterDiscountTotal + sgst + cgst;
  
      const orderId = selectedBill?.orderId;
      
      if (!orderId) {
        throw new Error('No order ID available. Please make sure the bill is held first.');
      }
  
      const orderResponse = await fetch('https://www.wazwanrestaurants.com/api/walkin-orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          orderId,
          selectedTable: selectedTable.name,
          items: selectedItems.map(item => ({
            itemId: item._id,
            itemName: item.itemName,
            quantity: item.quantity,
            price: item.totalPrice,
            variant: item.variant,
            discountPercentage:item.discountPercentage,
            totalPrice: item.totalPrice * item.quantity
          })),
          paymentMode: selectedPaymentMode,
          customerName: customerName || null,
          subTotal,
          discountPercentage: discount,
          discountAmount,
          sgst,
          cgst,
          totalAmount
        })
      });
  
      if (!orderResponse.ok) {
        const errorData = await orderResponse.json();
        throw new Error(errorData.message || 'Failed to create walk-in order');
      }
  
      const savedOrder = await orderResponse.json();
  
      if (selectedBill?._id) {
        await fetch(`https://www.wazwanrestaurants.com/api/held-bills/${selectedBill._id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
  
      await fetch(`https://www.wazwanrestaurants.com/api/tables/${selectedTable._id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ status: 'Available' })
      });
  
      setHeldBills(prev => {
        const newHeldBills = { ...prev };
        delete newHeldBills[selectedTable._id];
        return newHeldBills;
      });
  
      setTables(prev => 
        prev.map(t => 
          t._id === selectedTable._id 
            ? { ...t, status: 'Available' }
            : t
        )
      );
  
      return savedOrder;
    } catch (error) {
      console.error('Error saving walk-in order:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleHoldBill = async () => {
    if (selectedTable && selectedItems.length > 0) {
      try {
        setLoading(true);
        
        await fetch(`https://www.wazwanrestaurants.com/api/tables/${selectedTable._id}/status`, {
          method: 'PATCH',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ status: 'Occupied' }),
        });
  
        const subTotal = selectedItems.reduce((total, item) => 
          total + (item.totalPrice * item.quantity), 0
        );
  
        const billResponse = await fetch(`https://www.wazwanrestaurants.com/api/held-bills/${selectedTable._id}`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ 
            tableId: selectedTable._id,
            tableNumber: selectedTable.tableNumber,
            paymentMode: selectedPaymentMode,
            items: selectedItems.map(item => ({
              itemId: item._id,
              itemName: item.itemName,
              quantity: item.quantity,
              variant: item.variant,
              totalPrice: item.totalPrice,
              discountPercentage: discount // Add discount to each item
            })),
            discountPercentage: discount, // Add overall discount
            discountAmount: subTotal * (discount / 100) // Calculate and store discount amount
          }),
        });
  
        if (!billResponse.ok) throw new Error('Failed to save held bill');

        setHasUnsavedChanges(false);
        setHeldBills(prev => ({
          ...prev,
          [selectedTable._id]: selectedItems
        }));
        
        setTables(prev => 
          prev.map(t => 
            t._id === selectedTable._id 
              ? { ...t, status: 'Occupied' }
              : t
          )
        );

        setView('tables');
        setSelectedTable(null);
        setSelectedItems([]);
     } catch (err) {
      setError('Failed to hold bill');
    } finally {
      setLoading(false);
    }
  }
  setShowExitDialog(false);
};


  const handleDeleteHeldBill = async () => {
    if (!selectedBill?._id) {
      alert('No bill selected to delete');
      return;
    }
  
    if (!window.confirm('Are you sure you want to delete this bill?')) {
      return;
    }
  
    try {
      await axios.delete(`https://www.wazwanrestaurants.com/api/held-bills/${selectedBill._id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      await axios.patch(
        `https://www.wazwanrestaurants.com/api/tables/${selectedTable._id}/status`,
        { status: 'Available' },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      setHeldBills(prev => {
        const newHeldBills = { ...prev };
        delete newHeldBills[selectedTable._id];
        return newHeldBills;
      });
  
      setTables(prev => 
        prev.map(t => 
          t._id === selectedTable._id 
            ? { ...t, status: 'Available' }
            : t
        )
      );
  
      setSelectedItems([]);
      setSelectedBill(null);
      setHasUnsavedChanges(false);
      setView('tables');
      setSelectedTable(null);
  
      alert('Bill deleted successfully');
    } catch (error) {
      console.error('Failed to delete bill:', error);
      alert('Failed to delete bill: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleTableSelect = (table) => {
    setSelectedTable(table);
    setView('menu');
    if (table.status === 'Occupied') {
      const heldItems = heldBills[table._id] || [];
      setSelectedItems(heldItems);
      setHasUnsavedChanges(false);
    } else {
      setSelectedItems([]);
    }
  };

  const handleAddItem = (product) => {
    if (product.variants && product.variants.length > 0) {
      setSelectedProduct(product);
      setShowVariantModal(true);
    } else {
      handleVariantSelect(product, null);
    }
  };

  const handleVariantSelect = (product, variant) => {
    setSelectedItems(prevItems => {
      const itemToAdd = {
        _id: variant ? `${product._id}-${variant.name}` : product._id,
        itemName: variant ? `${product.itemName} (${variant.name})` : product.itemName,
        totalPrice: variant ? variant.price : product.totalPrice,
        quantity: 1,
        variant: variant ? variant.name : null
      };

      const existingItem = prevItems.find(item => item._id === itemToAdd._id);
      if (existingItem) {
        return prevItems.map(item =>
          item._id === itemToAdd._id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prevItems, itemToAdd];
    });
    setShowVariantModal(false);
    setHasUnsavedChanges(true);
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity < 1) {
      setSelectedItems(prevItems => 
        prevItems.filter(item => item._id !== itemId)
      );
    } else {
      setSelectedItems(prevItems =>
        prevItems.map(item =>
          item._id === itemId
            ? { ...item, quantity: newQuantity }
            : item
        )
      );
    }
    setHasUnsavedChanges(true);
  };


   const CustomerModal = ({ onClose, onSave }) => {
    const [name, setName] = useState('');

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Add Customer Name</h3>
          <input
            type="text"
            placeholder="Enter customer name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded-lg mb-4"
          />
          <div className="flex justify-end gap-4">
            <button
              onClick={() => onClose()}
              className="px-4 py-2 bg-gray-200 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                onSave(name);
                onClose();
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  const calculateTotal = () => {
    const subTotal = selectedItems.reduce((total, item) => 
      total + (item.totalPrice * item.quantity), 0
    );
    const discountAmount = subTotal * (discount / 100);
    return subTotal - discountAmount;
  };

  const calculateTableTotal = (items) => {
    return items.reduce((total, item) => 
      total + (item.totalPrice * item.quantity), 0
    );
  };

  const handleExit = () => {
    if (hasUnsavedChanges) {
      setShowExitDialog(true);
    } else {
      setView('tables');
      setSelectedTable(null);
      setSelectedItems([]);
    }
  };

  
  const VariantModal = ({ product, onClose, onSelect }) => {
    const handlePrintKOTAndHold = async () => {
      try {
        // First, add the item to selected items
        onSelect(product, null);
  
        // Then, hold the bill
        await handleHoldBill();
  
        // Navigate to print KOT with the updated items
        navigate('/print-kot3', {
          state: {
            product: {
              ...product,
              itemName: product.itemName
            },
            tableInfo: selectedTable, 
            fromModal: true
          }
        });
  
        // Close the modal
        onClose();
      } catch (error) {
        console.error('Failed to print KOT and hold bill:', error);
        alert('Failed to process request: ' + error.message);
      }
    };
  
    if (!product) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">{product.itemName} - Variants</h3>
          <div className="space-y-4">
            {product.variants.map((variant, index) => (
              <div
                key={index}
                className="p-3 border rounded hover:bg-gray-50 cursor-pointer"
                onClick={() => onSelect(product, variant)}
              >
                <div className="flex justify-between">
                  <span>{variant.name}</span>
                  <span>₹{variant.price}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrintKOTAndHold}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
            >
              <Printer className="mr-2" /> Print KOT & Hold
            </button>
            <button
              onClick={onClose}
              className="bg-gray-200 text-black px-4 py-2 rounded-lg hover:bg-gray-300 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

const renderTableCard = (table) => (
  <div
    key={table._id}
    className={`relative p-6 rounded-lg shadow-lg cursor-pointer ${
      table.status === 'Available' ? 'bg-green-100' : 'bg-red-100'
    }`}
    onClick={() => handleTableSelect(table)}
  >
    <div className="flex flex-col items-center">
      <h3 className="text-lg font-semibold mb-2">Table {table.tableNumber}</h3>
      <p className={`text-sm ${
        table.status === 'Available' ? 'text-green-600' : 'text-red-600'
      }`}>
        {table.status}
      </p>
      
      {table.status === 'Occupied' && heldBills[table._id] && (
        <div className="mt-3 w-full">
          <div className="bg-white bg-opacity-90 p-3 rounded-lg">
            <div className="flex items-center gap-2 text-blue-600 mb-2">
              <ShoppingBag size={16} />
              <span className="font-medium">Held Items (Table {table.tableNumber})</span>
            </div>
            <div className="space-y-1 max-h-32 overflow-y-auto">
              {heldBills[table._id].map((item, idx) => (
                <div key={idx} className="text-sm flex justify-between">
                  <span>{item.itemName} x{item.quantity}</span>
                  <span>₹{item.totalPrice * item.quantity}</span>
                </div>
              ))}
            </div>
            <div className="mt-2 pt-2 border-t border-gray-200">
              <div className="text-sm font-medium flex justify-between">
                <span>Total:</span>
                <span>₹{calculateTableTotal(heldBills[table._id])}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );

const renderMenu = () => (
  <div>
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <button 
          onClick={handleExit}
          className="border border-gray-300 px-8 py-4 rounded-lg flex items-center hover:bg-yellow-500 bg-yellow-300 text-black-600 text-lg font-bold transition-colors"
        >
          <ArrowLeft className="mr-4" /> Back
        </button>

        <div>
          <h2 className="text-2xl font-bold">Table: {selectedTable?.name}</h2>
          {selectedBill && (
            <div className="mt-2 p-4 bg-blue-50 border-l-4 border-blue-500 text-blue-700 rounded">
              <p className="text-sm">Order ID: {selectedBill.orderId}</p>
              <p className="text-sm">Loaded previously held items for this table</p>
            </div>
          )}
        </div>

        <div className="flex gap-4">
          <button 
            onClick={() => navigate('/print-kot', {
              state: {
                tableInfo: selectedTable,
                items: selectedItems
              }
            })}
            className={`bg-blue-500 text-white px-6 py-2 rounded-lg flex items-center ${
              selectedItems.length === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
            } transition-colors`}
            disabled={selectedItems.length === 0}
          >
            <Printer className="mr-2" /> Print KOT
          </button>
          <button
            onClick={handleHoldBill}
            className="bg-green-500 text-white px-6 py-2 rounded-lg flex items-center hover:bg-green-600 transition-colors"
          >
            Hold Bill
          </button>
          <button
            onClick={handleDeleteHeldBill}
            className="bg-red-500 text-white px-6 py-2 rounded-lg flex items-center hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex overflow-x-auto space-x-4 p-2 bg-white rounded-lg shadow">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-lg whitespace-nowrap ${
                selectedCategory === category
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="relative w-full max-w-md mb-6">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search items..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 border rounded-lg"
          />
        </div>

        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-3 max-h-[400px] overflow-y-auto p-2">
          {products
            .filter((product) => {
              const matchesSearch = product.itemName
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
              const matchesCategory = selectedCategory === 'All' || product.type === selectedCategory;
              return matchesSearch && matchesCategory;
            })
            .map((product) => (
              <div
                key={product._id}
                onClick={() => handleAddItem(product)}
                className="bg-white rounded-lg shadow hover:shadow-md transition-all duration-200 cursor-pointer transform hover:scale-105"
              >
                <div className="aspect-square relative">
                  <img
                    src={product.imageUrl || "/api/placeholder/100/100"}
                    alt={product.itemName}
                    className="w-full h-full object-cover rounded-t-lg"
                  />
                </div>
                <div className="p-2">
                  <h4 className="font-medium text-xs mb-1 truncate">{product.itemName}</h4>
                 
                  {product.variants.length > 0 && (
                    <span className="text-xs text-blue-500">+ {product.variants.length} variants</span>
                  )}
                </div>
              </div>
            ))}
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6 mt-6">
          <h3 className="text-xl font-semibold mb-4">Selected Items</h3>
          <div className="space-y-4 max-h-[300px] overflow-y-auto">
            {selectedItems.map((item) => (
              <div key={item._id} className="flex justify-between items-center p-4 border rounded-lg">
                <div>
                  <h4 className="font-medium">{item.itemName}</h4>
                  <p className="text-sm text-gray-600">₹{item.totalPrice} each</p>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex items-center bg-gray-100 rounded-lg">
                    <button
                      onClick={() => handleQuantityChange(item._id, item.quantity - 1)}
                      className="px-3 py-1 text-lg font-medium hover:bg-gray-200 rounded-l-lg"
                    >
                      -
                    </button>
                    <span className="px-4 py-1 font-medium">{item.quantity}</span>
                    <button
                      onClick={() => handleQuantityChange(item._id, item.quantity + 1)}
                      className="px-3 py-1 text-lg font-medium hover:bg-gray-200 rounded-r-lg"
                    >
                      +
                    </button>
                  </div>
                  <span className="font-medium">₹{item.totalPrice * item.quantity}</span>
                </div>
              </div>
            ))}
          </div>

        {/* Total Calculation with Discount */}
        {selectedItems.length > 0 && (
          <div className="w-full max-w-md">
            <div className="flex justify-between mb-2">
              <span>Subtotal:</span>
              <span>₹{selectedItems.reduce((total, item) => total + (item.totalPrice * item.quantity), 0)}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Discount ({discount}%):</span>
              <span>-₹{(selectedItems.reduce((total, item) => total + (item.totalPrice * item.quantity), 0) * (discount / 100)).toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-xl font-bold border-t pt-2">
              <span>Total</span>
              <span>₹{calculateTotal().toFixed(2)}</span>
            </div>
          </div>
        )}
        </div>
      </div>
          
      <div className="flex flex-col items-center space-y-6 p-6 bg-gray-100 rounded-lg shadow-md max-w-xl mx-auto mt-6">
        {/* Discount Input */}
        <div className="w-full max-w-md">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Discount Percentage
          </label>
          <div className="flex items-center">
            <input
              type="number"
              min="0"
              max="100"
              value={discount}
              onChange={(e) => setDiscount(Number(e.target.value))}
              className="w-full p-2 border rounded-lg mr-2"
              placeholder="Enter discount %"
            />
            <span>%</span>
          </div>
        </div>

        {/* Customer Name */}
        <div className="w-full max-w-md flex items-center">
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className="w-full p-2 border rounded-lg mr-2"
            placeholder="Customer Name (Optional)"
          />
          <button 
            onClick={() => setShowCustomerModal(true)}
            className="p-2 bg-blue-500 text-white rounded-lg"
          >
            <UserPlus />
          </button>
        </div>

        <div className="text-lg font-semibold flex items-center">
          <BadgeIndianRupee className="mr-2" />
          Payment Mode 
        </div>
        <div className="flex justify-center gap-4">
          {['Cash', 'UPI', 'Card'].map((mode) => (
            <label key={mode} className="flex flex-col items-center cursor-pointer">
              <input
                type="radio"
                name="paymentMode"
                value={mode}
                checked={selectedPaymentMode === mode}
                onChange={(e) => setSelectedPaymentMode(e.target.value)}
                className="hidden"
              />
              <span
                className={`px-6 py-2 rounded-md transition-all duration-200 ${
                  selectedPaymentMode === mode
                    ? 'bg-blue-500 text-white shadow-md transform scale-105'
                    : 'bg-white text-black hover:bg-gray-200'
                }`}
              >
                {mode}
              </span>
            </label>
          ))}
        </div>

        <button
          onClick={async () => {
            try {
              setLoading(true);
              const savedOrder = await saveWalkInOrder();
              navigate(`/generate-bill-2/${savedOrder._id}`, {
                state: {
                  tableInfo: selectedTable,
                  items: selectedItems,
                },
              });
            } catch (error) {
              setError('Failed to process bill: ' + error.message);
            } finally {
              setLoading(false);
            }
          }}
          className={`bg-green-500 text-white px-8 py-3 rounded-lg flex items-center ${
            selectedItems.length === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'
          } transition-colors`}
          disabled={selectedItems.length === 0}
        >
          <PrinterCheck className="mr-2" /> Print Bill
        </button>
      </div>
    </div>
  );

    {showVariantModal && (
      <VariantModal
        product={selectedProduct}
        onClose={() => setShowVariantModal(false)}
        onSelect={handleVariantSelect}
      />
    )}
  </div>
);

return (
  <UnsavedChangesHandler hasUnsavedChanges={hasUnsavedChanges}>
    <div className="min-h-screen bg-gray-100">
      {error && (
        <div className="p-4 bg-red-50 border-l-4 border-red-500 text-red-700 mb-4">
          <p>{error}</p>
        </div>
      )}
      
      {view === 'tables' && (
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Select a Table</h2>
            <button
              onClick={() => navigate('/create-KOT')}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Create Single KOT
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {tables.map(renderTableCard)}
          </div>
        </div>
      )}

      {view === 'menu' && renderMenu()}

      {showExitDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">Exit Options</h3>
            <p className="text-gray-600 mb-6">Would you like to hold this bill or exit completely?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleHoldBill}
                className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                Hold Bill
              </button>
              <button
                onClick={() => {
                  setShowExitDialog(false);
                  setView('tables');
                  setSelectedTable(null);
                  setSelectedItems([]);
                  setHasUnsavedChanges(false);
                }}
                className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-gray-600">Loading...</p>
          </div>
        </div>
      )}
    </div>
  </UnsavedChangesHandler>
);
};

export default NewSales;