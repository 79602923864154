import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Printer } from 'lucide-react';

const GenerateBill = () => {
  const { orderId } = useParams();
  const { token } = useAuth();
  const [currentTime] = useState(new Date());
  const [order, setOrder] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderResponse = await fetch(`https://www.wazwanrestaurants.com/api/saved-orders/${orderId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!orderResponse.ok) {
          throw new Error(`Failed to fetch order data: ${orderResponse.statusText}`);
        }
        
        const orderData = await orderResponse.json();
        
        if (!orderData || !Array.isArray(orderData.items)) {
          throw new Error('Invalid order data structure');
        }
        
        setOrder(orderData);

        const settingsResponse = await fetch('https://www.wazwanrestaurants.com/api/settings', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!settingsResponse.ok) {
          throw new Error(`Failed to fetch settings data: ${settingsResponse.statusText}`);
        }
        
        const settingsData = await settingsResponse.json();
        setSettings(settingsData);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message || 'Failed to load bill information');
        setLoading(false);
      }
    };

    if (token && orderId) {
      fetchData();
    }
  }, [orderId, token]);

  const calculateItemTotal = (price, quantity) => {
    return (price * quantity) || 0;
  };

  const calculateSubTotal = (items) => {
    if (!Array.isArray(items)) return 0;
    return items.reduce((total, item) => total + (calculateItemTotal(item.price, item.quantity)), 0);
  };

  const calculateTax = (subtotal) => {
    const SGST = (subtotal * 0.025) || 0;
    const CGST = (subtotal * 0.025) || 0;
    return { SGST, CGST };
  };

  const calculateGrandTotal = (subtotal) => {
    const { SGST, CGST } = calculateTax(subtotal);
    return subtotal + SGST + CGST;
  };

  const handlePrint = () => {
    window.print();
  };

  if (!token) return <div className="text-base text-center p-4">Please log in</div>;
  if (loading) return <div className="text-base text-center p-4">Loading...</div>;
  if (error) return <div className="text-base text-center p-4">{error}</div>;
  if (!order || !settings) return <div className="text-base text-center p-4">No bill found</div>;

  const subtotal = calculateSubTotal(order.items);
  const { SGST, CGST } = calculateTax(subtotal);
  const grandTotal = calculateGrandTotal(subtotal);

  return (
    <div className="w-64 mx-auto text-sm">
      {/* Print Button */}
      <div className="print:hidden mb-4">
        <button onClick={handlePrint} className="flex items-center px-4 py-2 bg-blue-500 text-white rounded text-base">
          <Printer className="mr-2" size={18} />
          Print
        </button>
      </div>

      {/* Bill Content */}
      <div className="text-center mb-4">
        <div className="text-xl font-bold mb-2">{settings.restaurantName}</div>
        <div className="text-base mb-1">{settings.location}</div>
        <div className="text-base mb-1">Ph: {settings.phoneNumber}</div>
        {settings.gstin && <div className="text-base mb-1">GSTIN: {settings.gstin}</div>}
      </div>

      <div className="text-base mb-4">
        <div className="mb-1">Bill #: {order.orderId}</div>
        <div className="mb-1">Date: {currentTime.toLocaleDateString()}</div>
        <div className="mb-1">Table: {order.selectedTable}</div>
      </div>

      <div className="border-t-2 border-b-2 border-gray-300 my-3"></div>

      {/* Items */}
      <div className="mb-4">
        {order.items.map((item, index) => (
          <div key={index} className="flex text-base justify-between mb-2">
            <div className="w-32 truncate">{item.name}</div>
            <div className="text-right">
              <div>{item.quantity}x₹{item.price}</div>
              <div>₹{calculateItemTotal(item.price, item.quantity)}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="border-t-2 border-gray-300 my-3"></div>

      {/* Bill Summary */}
      <div className="text-base">
        <div className="flex justify-between mb-1">
          <span>Subtotal:</span>
          <span>₹{subtotal.toFixed(2)}</span>
        </div>
        <div className="flex justify-between mb-1">
          <span>SGST:</span>
          <span>₹{SGST.toFixed(2)}</span>
        </div>
        <div className="flex justify-between mb-1">
          <span>CGST:</span>
          <span>₹{CGST.toFixed(2)}</span>
        </div>
        <div className="flex justify-between font-bold border-t-2 border-gray-300 mt-2 pt-2 text-lg">
          <span>Total:</span>
          <span>₹{grandTotal.toFixed(2)}</span>
        </div>
      </div>

      <div className="text-center mt-4 text-base">
        <p>{settings.note || 'Thank you!'}</p>
      </div>

      <style>
        {`
          @media print {
            @page {
              size: 80mm auto;
              margin: 0;
            }
            body {
              width: 80mm;
              padding: 5mm;
            }
            .print\\:hidden {
              display: none !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default GenerateBill;