import React, { useEffect } from 'react';

const Toast = ({ message, type = 'success', onClose, duration = 3000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Blurred background overlay */}
      <div className="absolute inset-0 bg-black/30 backdrop-blur-sm" />
      
      {/* Toast content */}
      <div className={`
        relative
        px-6 py-4
        rounded-lg
        shadow-xl
        flex items-center
        space-x-3
        transform
        transition-all
        duration-300
        ${type === 'success' 
          ? 'bg-gradient-to-r from-amber-700 to-amber-600 text-amber-50' 
          : 'bg-gradient-to-r from-red-700 to-red-600 text-red-50'
        }
      `}>
        {/* Success icon */}
        {type === 'success' && (
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M5 13l4 4L19 7" 
            />
          </svg>
        )}
        
        {/* Message */}
        <span className="text-lg font-medium">{message}</span>
        
        {/* Close button */}
        <button 
          onClick={onClose}
          className="ml-4 text-2xl font-semibold hover:text-amber-200 transition-colors"
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default Toast;